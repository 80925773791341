import { NavigationIconProps } from './HomeIcon'

const CarServicesIcon = ({ size }: NavigationIconProps) => {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.98006 2.67078V4.24078C9.98006 4.40078 9.85006 4.52078 9.70006 4.52078H5.72006C5.56006 4.52078 5.44006 4.39078 5.44006 4.24078V2.67078C5.44006 2.05078 5.94006 1.55078 6.56006 1.55078H8.87006C9.48006 1.55078 9.98006 2.05078 9.98006 2.67078Z'
        fill='#00A754'
      />
      <path
        d='M18.17 4.93164H5.83005C4.71005 4.93164 3.80005 5.84164 3.80005 6.96164V20.4216C3.80005 21.5416 4.71005 22.4516 5.83005 22.4516H18.17C19.29 22.4516 20.2001 21.5416 20.2001 20.4216V6.96164C20.2001 5.84164 19.29 4.93164 18.17 4.93164ZM10.21 19.6516C7.58005 19.6516 5.44005 17.5216 5.44005 14.8816C5.44005 12.2516 7.57005 10.1116 10.21 10.1116C12.85 10.1116 14.98 12.2416 14.98 14.8816C14.98 17.5216 12.84 19.6516 10.21 19.6516ZM18.67 10.9116C18.67 12.9816 18.59 14.3316 17.47 14.3316C16.41 14.3316 15.88 13.8216 15.88 11.0716C15.88 9.44164 15.17 8.98164 14.61 8.62164C14.26 8.39164 13.89 8.16164 13.89 7.69164C13.89 6.92164 14.48 6.57164 15.07 6.57164C15.08 6.57164 17.53 6.57164 17.53 6.57164C18.16 6.57164 18.68 7.08164 18.68 7.71164C18.67 7.71164 18.67 10.7816 18.67 10.9116Z'
        fill='#00A754'
      />
      <path
        d='M7.69002 16.2313C7.66002 17.5713 8.82002 18.6713 10.21 18.6713C11.6 18.6713 12.7 17.5813 12.73 16.2413C12.77 14.0913 10.61 13.5113 10.21 11.1113C9.70002 13.5513 7.74002 13.8113 7.69002 16.2313Z'
        fill='#00A754'
      />
    </svg>
  )
}
export default CarServicesIcon
