import { HStack, Link } from '@chakra-ui/react'
import karnamehLogo from '../../../assets/svg/karnameh-logo-new.svg'
import { statics } from './data'
import AppbarMenu from './AppbarMenu'
import { userIsLoggedIn } from '../../../utils/userAuthTool'
import { Icon } from '@chakra-ui/icons'
import { routePaths } from '../../../routes/urls'
import { MdOutlineNotifications } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import AppbarProfile from './AppbarProfile'
import { generateKarnameUrl } from '../../../utils/redirectionManager'

const DesktopAppbar = () => {
  const { headerMenu, profileMenu } = statics
  const isLoggedIn = userIsLoggedIn()
  const navigator = useNavigate()

  return (
    <HStack
      align='center'
      justify='space-between'
      py='1.31px'
      boxSize='full'
      position='sticky'
      width='100vw'
      bg='white'
      zIndex='2'
      boxShadow='0px 0px 8px rgba(0, 0, 0, 0.05)'
      top='0'
      height='64px'
      gap='10px'
      paddingX='4rem'
    >
      <HStack>
        <Link
          backgroundImage={karnamehLogo}
          marginEnd='2rem'
          href={generateKarnameUrl()}
          h='1.61rem'
          w='6.5rem'
        />
        <AppbarMenu list={headerMenu} />
      </HStack>
      <HStack>
        {isLoggedIn && (
          <Icon
            onClick={() => navigator(routePaths.NOTIFICATION_LIST.url)}
            as={MdOutlineNotifications}
            boxSize='24px'
            color='icon.default'
            cursor='pointer'
          />
        )}
        <AppbarProfile list={profileMenu} />
      </HStack>
    </HStack>
  )
}

export default DesktopAppbar
