import { Drawer, DrawerOverlay } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useCloseBottomSheet } from '../../store/slices/bottomSheetSlice'
import { RootState } from '../../store/store'
import { BottomSheetsContent } from './BottomSheets'

export const BottomSheetManager: React.FC = () => {
  const closeBS = useCloseBottomSheet()
  const { contentType, isOpen, closeOnOverlayClick } = useSelector(
    (state: RootState) => state.bottomSheet,
  )

  useEffect(() => {
    window.addEventListener('popstate', closeBS)
    return () => {
      window.removeEventListener('popstate', closeBS)
    }
  }, [])

  if (!contentType) return null
  const ContentComponent = BottomSheetsContent[contentType]

  return (
    <Drawer
      blockScrollOnMount={false}
      closeOnOverlayClick={closeOnOverlayClick}
      placement='bottom'
      isOpen={isOpen}
      onClose={closeBS}
      autoFocus={false}
    >
      <DrawerOverlay />
      <ContentComponent />
    </Drawer>
  )
}
