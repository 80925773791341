export const colors = {
  primary: {
    hover: '#00A051',
    enable: '#00A754',
    focus: '#00934A',
    dragged: '#009A4D',
    selected: '#EBF8F1',
    activated: '#E0F4EA',
    deactive: '#EEEEEE',
  },
  accent: {
    main: '#5D5FEF',
    hover: '#64559C',
    enable: '#694FB4',
    focus: '#5C4E8F',
    dragged: '#605296',
    selected: '#F3F2F8',
    activated: '#EDEBF4',
    deactive: '#EEEEEE',
  },
  critical: {
    hover: '#C50000',
    enable: '#CD0000',
    focus: '#B40000',
    dragged: '#BD0000',
  },
  background: {
    default: '#FAFAFA',
    'divider-border': '#F2F2F2',
    borderOrDivider: '#c9cccf',
    divider: '#EDEDED',
    'border-divider': '#C9CCCF',
  },
  surface: {
    default: '#FFFFFF',
    'divider-border': '#EBEBEB',
    'main-bg': '#F5F5F5',
    premium: '#FDEFD7',
    'light-green': '#F6FCF8',
  },
  text: {
    primary: '#202021',
    secondary: '#6E6E6E',
    deactive: '#A9A9AA',
    white: '#FFFFFF',
    green: '#00A039',
    greenInactive: '#C7E5C8',
    'grey-800': '#424242',
    premium: '#C09A56',
  },
  icon: { default: '#6E6E6E', deactive: '#A9A9AA', invert: '#FFFFFF', notification: '#F13739' },
  warning: { surface: '#FFF1E5', 'divider-border': '#FEA764', text: '#FA6B00', icon: '#FF7700' },
  informative: {
    surface: '#EBF9FC',
    'divider-border': '#6BC2ED',
    'white-divider': '#FAFAFA',
    text: '#006ED8',
    icon: '#0078FB',
    slider: '#DBDBDC',
  },
  error: { surface: '#FFF4F4', 'divider-border': '#FD5749', text: '#CD0000', icon: '#DF1D17' },
  success: { surface: '#EBF8F1', 'divider-border': '#B8EFD4', text: '#2EB974', icon: '#5CCB94' },

  illustration: {
    darkgreen: '#005542',
    summergreen: '#006E37',
    springgreen: '#87C765',
    brandgreen: '#00A754',
    yellow: '#E9C345',
    orange: '#F58220',
    white: '#FFFFFF',
    ocean: '#1CB7DA',
    darkblue: '#107BC1',
    autumnred: '#E86E60',
    darkred: '#884654',
    black: '#202021',
    darkgray: '#A9A9AA',
    lightgray: '#EEEEEE',
  },
  decorative: {
    1: { text: '#C12FCD', icon: '#C73DD3', 'divder-border': '#E39DE8', surface: '#FAEDFB' },
    2: { text: '#FC00A3', icon: '#FF11AB', 'divder-border': '#FF86D4', surface: '#FFE8F7' },
    3: { text: '#A10B6E', icon: '#B30C7B', 'divder-border': '#F78CD3', surface: '#FDE8F6' },
    4: { text: '#F4770D', icon: '#F58220', 'divder-border': '#F9B77E', surface: '#FDEBDC' },
    5: { text: '#6514FF', icon: '#7227FF', 'divder-border': '#B289FF', surface: '#F2EBFF' },
    6: { text: '#9A0606', icon: '#AD0606', 'divder-border': '#FA7D7D', surface: '#FFE8E8' },
    7: { text: '#27C0E3', icon: '#38C5E5', 'divder-border': '#8FDEF0', surface: '#E5F8FC' },
    8: { text: '#00B906', icon: '#00CD07', 'divder-border': '#6BFF6F', surface: '#E0FFE1' },
    9: { text: '#566683', icon: '#5E6F8F', 'divder-border': '#ADB7C9', surface: '#F4F5F8' },
    10: { text: '#836838', icon: '#90733E', 'divder-border': '#D9C7A6', surface: '#F0E9DD' },
  },
  transparent: 'transparent',
}

export const opacity = (color: string, opacity: number) => {
  if (opacity >= 1 || opacity < 0) return color
  return `${color}${Math.ceil(opacity * 256).toString(16)}`
}
