import { buildApiMutationHook, buildApiQueryNoParamsHook } from '../../utils/api'
import { providerAxios } from '../../utils/base.api'
import { CURRENT_USER, UPDATE_CURRENT_USER } from '../endpoints'
import { CurrentUserDTO, UpdateCurrentUserParams } from './User.dto'
import { queryClient } from '../../utils/queryClient'

export const useGetCurrentUser = buildApiQueryNoParamsHook<CurrentUserDTO>('current-user', () =>
  providerAxios.get(CURRENT_USER),
)

export const useUpdateCurrentUser = buildApiMutationHook<UpdateCurrentUserParams | FormData>(
  (params) => providerAxios.put(UPDATE_CURRENT_USER, params),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries('current-user')
      options?.onSuccess?.(...args)
    },
  }),
)
