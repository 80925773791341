import { REFRESH_TOKEN } from '../api/endpoints'
import { clearCredentialCookies, removeCookie, setAccessTokenCookie } from '../utils/cookieManager'
import { ACCESS_TOKEN } from '../utils/consts'
import { routePaths } from '../routes/urls'
import { CheckOtpResponseDTO } from '../api/auth/Auth.dto'
import { AxiosResponse } from 'axios'
import { providerAxios } from '../utils/base.api'
import { clearCredentialsFromLS } from '../utils/localStorageManager'

const InterceptorComponent = () => {
  const onResponseFulfilled:
    | ((
        value: AxiosResponse<unknown, unknown>,
      ) => AxiosResponse<unknown, unknown> | Promise<AxiosResponse<unknown, unknown>>)
    | null
    | undefined = (response) => response

  providerAxios.interceptors.response.use(onResponseFulfilled, async (error) => {
    const {
      config,
      response: { status },
    } = error
    switch (config.url) {
      case REFRESH_TOKEN:
        if (status !== 200) {
          removeCookie(ACCESS_TOKEN)
          window.location.pathname = routePaths.AUTH.url
        }
        break
      default:
        if (status === 401) {
          clearCredentialCookies()
          clearCredentialsFromLS()
          try {
            const response = await providerAxios.post<CheckOtpResponseDTO>(REFRESH_TOKEN)
            if (response && response.status === 200) {
              setAccessTokenCookie(response.data.access)
              return providerAxios(config)
            }
          } catch (err) {
            return err
          }
        } else {
          return error
        }
        break
    }
  })

  return <></>
}
export default InterceptorComponent
