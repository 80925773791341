import { NavigationIconProps } from './HomeIcon'

const MenuIcon = ({ type }: NavigationIconProps) => {
  return (
    <svg
      width={type === 'component' ? '38' : '24'}
      height={type === 'component' ? '36' : '24'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z'
          fill='#6E6E6E'
        />
      </g>
      <defs>
        <clipPath id='clip0_1599_1085'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default MenuIcon
