import { getAccessTokenFromCookie } from './cookieManager'
import jwtDecode from 'jwt-decode'
import { JWTDataDTO } from '../api/user/User.dto'

export const userIsLoggedIn = () => {
  return getAccessTokenFromCookie()
}

export function getPersistentUserToken(): string | undefined {
  return getAccessTokenFromCookie() ?? undefined
}

export const getUserDataFromToken = (token?: string | undefined) => {
  if (token) return jwtDecode<JWTDataDTO>(token)
  const accessToken = getAccessTokenFromCookie()
  if (accessToken) return jwtDecode<JWTDataDTO>(getAccessTokenFromCookie())
  return undefined
}
