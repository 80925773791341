export const stringNormalizers = (url: string, target: string, value: string) => {
  if (url.endsWith('*')) {
    return url.replace('*', '').replace(target, value)
  }
  return url.replace(target, value)
}

export const baseUrlNormalizer = (url: string, pathname: string | string[]) => {
  const baseUrl = url.endsWith('/') ? url.slice(0, -1) : url
  if (pathname instanceof Array) {
    const finalUrl = baseUrl
    const finalPath = pathname.reduce((acc, item) => `${acc}/${item}`)
    return `${finalUrl}/${finalPath}/`
  } else {
    return `${baseUrl}/${pathname}/`
  }
}

export const appbarNameNormalizer = (title: string | undefined, alternative: string) => {
  if (!title || title.length === 0) return alternative
  return title
}
