import { Box, Text } from '@chakra-ui/react'

import { usePriceEstimation } from '@src/api/car-profile/CarProfile.api'
import BarValues from '@src/components/car-estimation/price-estimator/BarValues'
import Dividers from '@src/components/car-estimation/price-estimator/dividers'
import MinMaxZeroEstimation from '@src/components/car-estimation/price-estimator/minMaxZeroEstimation'

interface Props {
  carProfileId: string | number
}

function PriceEstimator({ carProfileId }: Props) {
  const { data, isError } = usePriceEstimation({ id: +carProfileId })

  return !isError ? (
    <>
      {data && (
        <Box display={'flex'} flexDirection={'column'} w={'full'}>
          <Dividers {...data} />
          <BarValues />
          <MinMaxZeroEstimation {...data} />
        </Box>
      )}
    </>
  ) : (
    <Text color={'error.text'} fontWeight={'bold'} textAlign={'center'}>
      تخمین قیمت این پروفایل موجود نست.
    </Text>
  )
}

export default PriceEstimator
