import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

type UTMParamsType = { [key: string]: string }

type Props = {
  children: JSX.Element
}
const UtmParamsProvider = ({ children }: Props) => {
  const [searchParams] = useSearchParams()
  const [, setCashedUtmParams] = useState<UTMParamsType>({})
  // const cookies = new Cookies()
  // const cookieDomain = import.meta.env.MODE === 'development' ? '' : 'karnameh.com'

  useEffect(() => {
    const newUtmParams: UTMParamsType = {}
    searchParams.forEach((value, key) => {
      if (newUtmParams[key]) {
        delete newUtmParams[key]
      }
      newUtmParams[key] = value
    })
    handleUtmChanges(newUtmParams)
  }, [searchParams])
  const handleUtmChanges = (newUtmParams: UTMParamsType) => {
    // const newUtmParamsCookie = JSON.stringify(newUtmParams)
    // cookies.set(UTM_COOKIES, newUtmParamsCookie, { path: '/', domain: cookieDomain })
    setCashedUtmParams(newUtmParams)
  }

  return <>{children}</>
}

export default UtmParamsProvider
