import { CloseIcon } from '@chakra-ui/icons'
import { Center, Divider, DrawerContent, DrawerHeader, Flex, Spinner, Text } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  useGetAftermarketOrderDetail,
  useGetAftermarketOrderPayments,
} from '../../api/orders/Orders.api'
import { BottomSheetActions } from '../../store/slices/bottomSheetSlice'
import { PriceRowItem } from './PriceRowItem'

export const OrderDetailBS = () => {
  const params = window.location.href.split('/')
  const orderId = window.location.href.endsWith('payment-detail')
    ? params?.[params.length - 2]
    : params?.[params.length - 1]

  const dispatch = useDispatch()
  const closeBS = useCallback(() => {
    dispatch(BottomSheetActions.closeBottomSheet())
  }, [])

  const { data, isLoading } = useGetAftermarketOrderPayments(
    { orderId: parseInt(orderId ?? '-1') },
    { enabled: Boolean(orderId) },
  )
  const { data: orderData } = useGetAftermarketOrderDetail({ orderId: parseInt(orderId ?? '-1') })

  return (
    <DrawerContent borderTopRadius='1.75rem'>
      <DrawerHeader
        alignItems='center'
        justifyContent='center'
        w='full'
        display='flex'
        flexDir='row'
        p='1.63rem 1rem 1.13rem'
        pos='relative'
      >
        <CloseIcon onClick={closeBS} boxSize='6' pos='absolute' left='1rem' p='1' />
        <Text textStyle='titleXSmall' fontWeight='bold' textColor='text.grey-800'>
          جزییات سرویس
        </Text>
      </DrawerHeader>
      {isLoading ? (
        <Center>
          <Spinner color='illustration.brandgreen' size='md' />
        </Center>
      ) : (
        <Flex flexDir='column' w='full' py='1rem' px='1.5rem' bg='white'>
          <Text textStyle='bodySmallSemibold' mb='0.5rem'>
            قطعات
          </Text>
          {data?.[0]?.payment_items
            ?.filter((item) => item.item_type === 'part')
            .map((item) => (
              <PriceRowItem key={item.id} title={item.title} price={item.price} />
            ))}
          <Divider m='0.25rem 0 1.25rem 0' borderBottomColor='background.borderOrDivider' />
          <Text textStyle='bodySmallSemibold' mb='0.5rem'>
            خدمات
          </Text>
          {data?.[0].payment_items
            .filter((item) => item.item_type === 'service' && item.title !== 'ایاب و ذهاب')
            .map((item) => (
              <PriceRowItem key={item.id} title={item.title} price={item.price} />
            ))}
          {Boolean(orderData?.address) && (
            <>
              <Divider m='0.25rem 0 1.25rem 0' borderBottomColor='background.borderOrDivider' />
              <PriceRowItem
                title='مجموع هزینه قطعات و خدمات'
                price={
                  data?.[0]?.payment_items
                    .filter((item) => ['service', 'part'].includes(item.item_type))
                    .reduce((acc, item) => (acc += item.price), 0) ?? 0
                }
              />
              <PriceRowItem
                title='هزینه ایاب و ذهاب'
                price={
                  data?.[0].payment_items.filter(
                    (item) => item.item_type === 'service' && item.title !== 'ایاب و ذهاب',
                  )[0]?.price ?? 0
                }
              />
              <PriceRowItem title='تخفیف سرویس' price={data?.[0]?.total_discount ?? 0} />
            </>
          )}
          <Divider m='0.25rem 0 1.25rem 0' borderBottomColor='background.borderOrDivider' />
          <Flex w='full' justifyContent='space-between' mb='0.75rem !important'>
            <Text textStyle='bodySmallSemibold' mb='0.5rem'>
              جمع کل
            </Text>
            <Text textStyle='bodySmallSemibold' color='informative.text'>
              {data?.[0]?.total_payable_price.toLocaleString()} تومان
            </Text>
          </Flex>
        </Flex>
      )}
    </DrawerContent>
  )
}
