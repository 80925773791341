import { ForwardedRef, forwardRef, useCallback } from 'react'

export type FileWithPreview = {
  file: File
  preview: string
}

type PropsType = { captureMode?: boolean; changeHandler: (image: FileWithPreview) => void }

const ImageInputFC = (props: PropsType, ref: ForwardedRef<HTMLInputElement>) => {
  const inputChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      props.changeHandler({ file: files[0], preview: URL.createObjectURL(files[0]) })
    }
  }, [])
  return props.captureMode ? (
    <input
      ref={ref}
      type='file'
      accept='image/*'
      onChange={inputChangeHandler}
      capture='environment'
      hidden
    />
  ) : (
    <input ref={ref} type='file' accept='image/*' onChange={inputChangeHandler} hidden />
  )
}
export const ImageInput = forwardRef<HTMLInputElement, PropsType>(ImageInputFC)
