import posthog from 'posthog-js'
import { getUserDataFromToken } from './userAuthTool'
import { isTwaApp } from './twaUtils'

export const capturePageView = () => {
  if (isTwaApp()) posthog.capture('$pageview')
}

export const identifyUserInPostHog = () => {
  const jwtData = getUserDataFromToken()

  if (jwtData && isTwaApp()) {
    posthog.identify(jwtData.username, {
      first_name: jwtData.first_name,
      last_name: jwtData.last_name,
    })
  }
}

export const resetPostHogIdentity = () => {
  if (isTwaApp()) posthog.reset()
}

export const registerPostHogSuperProperties = (appVersion: string) => {
  posthog?.register({ is_twa: isTwaApp(), app_version: appVersion })
}

export const configurePostHog = (appVersion: string | null) => {
  if (isTwaApp()) {
    posthog?.set_config({
      capture_pageview: true,
      capture_pageleave: true,
    })
    registerPostHogSuperProperties(appVersion || 'Unknown')
    identifyUserInPostHog()
  }
}
