import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CurrentUserDTO } from '../../api/user/User.dto'

interface ProfileState {
  data: CurrentUserDTO | null
  selected: string
}

const initialState: ProfileState = {
  data: null,
  selected: 'خودرو‌های من',
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<CurrentUserDTO>) => {
      state.data = action.payload
    },
    setSelected: (state, action: PayloadAction<string>) => {
      state.selected = action.payload
    },
  },
})

export const { setData, setSelected } = profileSlice.actions

export default profileSlice.reducer
