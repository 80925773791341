import { Box, Text } from '@chakra-ui/react'

import { CarPriceEstimationDetailDto } from '@src/api/car-profile/CarProfile.dto'

type Props = CarPriceEstimationDetailDto

function MinMaxZeroEstimation(data: Props) {
  return (
    <Box className='min-max-zero-price'>
      {/* max */}
      {data.max_price && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          borderBottom={'1px'}
          borderBottomColor={'gray.300'}
          pb={4}
          _last={{
            border: 0,
          }}
        >
          <Text fontSize={'14px'} color={'#6E6E6E'}>
            حداکثر قیمت منصفانه
          </Text>
          <Text fontSize={'12px'}>
            {data.max_price.toLocaleString()}
            <Text as={'span'} fontSize={'8px'} color={'#A9A8AA'}>
              (تومان)
            </Text>
          </Text>
        </Box>
      )}

      {/* min */}
      {data.min_price && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          borderBottom={'1px'}
          borderBottomColor={'gray.300'}
          py={4}
          _last={{
            border: 0,
          }}
        >
          <Text fontSize={'14px'} color={'#6E6E6E'}>
            حداقل قیمت منصفانه
          </Text>
          <Text fontSize={'12px'}>
            {data.min_price.toLocaleString()}
            <Text as={'span'} fontSize={'8px'} color={'#A9A8AA'}>
              (تومان)
            </Text>
          </Text>
        </Box>
      )}

      {/* zero */}
      {data.zero && (
        <Box display={'flex'} justifyContent={'space-between'} pt={4}>
          <Text fontSize={'14px'} color={'#6E6E6E'}>
            قیمت صفر
          </Text>
          <Text fontSize={'12px'}>
            {data.zero}
            <Text as={'span'} fontSize={'8px'} color={'#A9A8AA'}>
              (تومان)
            </Text>
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default MinMaxZeroEstimation
