import {
  Center,
  CircularProgress,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react'
import { CloseIcon, Icon, SearchIcon } from '@chakra-ui/icons'
import { queryClient } from '../../../utils/queryClient'
import { MdArrowDropDown } from 'react-icons/md'
import React, { useState } from 'react'
import { BrandModelType } from '../../../api/brand-models/BrandModel.dto'
import { useBrandModels } from '../../../api/brand-models/BrandModels.api'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { p2eDigitConverter } from '../../../utils/numberUtils'

type DataProps = {
  onClose: () => void
  onSubmit: (brand: BrandModelType) => void
}
const SelectBrandModelBS = () => {
  const { onClose, onSubmit } = useSelector((state: RootState): DataProps => state.bottomSheet.data)
  const [query, setQuery] = useState<string>('')
  const searchBrandModels = useBrandModels(
    { text: p2eDigitConverter(query) },
    { enabled: query.length >= 3 },
  )
  return (
    <DrawerContent h='50vh' borderTopRadius='8px' pt='24px' pb='0!'>
      <DrawerHeader
        py='0!'
        px='16px'
        justifyContent='space-between'
        w='full'
        display='flex'
        flexDir='row'
      >
        <Text textStyle='titleXSmall' textColor='text.grey-800'>
          انتخاب سیستم(برند ماشین)
        </Text>
        <Center>
          <CloseIcon onClick={onClose} boxSize='3' />
        </Center>
      </DrawerHeader>
      <DrawerBody w='full'>
        <Flex w='full' h='full' overflow='hidden' flexDir='column'>
          <FormControl
            position='sticky'
            variant='floating-icon'
            id='karnameh-app-firs-name'
            mt='24px'
          >
            <InputGroup>
              <Input
                onChange={(value) => {
                  setQuery(value.target.value)
                  if (!query || query.length < 3) return
                  queryClient.invalidateQueries('brand-models')
                  searchBrandModels.refetch()
                }}
                required={false}
                borderRadius='8px'
                type='text'
                w='full'
                py='10px!'
                px='16px'
                outline='0px'
                border='1px'
                placeholder='جستجوی سیستم/برند ماشین'
                _focusVisible={{
                  borderColor: 'accent.enable',
                }}
                borderColor='accent.enable'
                _placeholder={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '32px',
                  color: 'text.secondary',
                }}
              />
              <InputLeftElement>
                <Icon boxSize='18px' as={SearchIcon} color='text.deactive' />
              </InputLeftElement>
              <InputRightElement>
                {searchBrandModels.isLoading || searchBrandModels.isRefetching ? (
                  <CircularProgress size='8' color='accent.enable' isIndeterminate />
                ) : (
                  <Icon boxSize='24px' as={MdArrowDropDown} color='text.secondary' />
                )}
              </InputRightElement>
            </InputGroup>

            {/* It is important that the Label comes after the Control due to css selectors */}
            <FormLabel
              _focus={{
                textColor: 'accent.enable',
              }}
              textStyle='captionLargeRegular'
              textColor='accent.enable'
              backgroundColor='white'
            >
              سیستم(برند ماشین)
            </FormLabel>
          </FormControl>
          <Stack
            filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.05))'
            borderRadius='8px'
            bg='white'
            w='full'
            height='400px'
            id='brands-list'
            overflowX='hidden'
            overflowY='auto'
          >
            {searchBrandModels.data?.data?.map((brand, index) => {
              return (
                <Text
                  onClick={() => {
                    onSubmit(brand)
                  }}
                  textStyle='bodySmallRegular'
                  borderBottom='2px solid'
                  borderColor='background.divider'
                  py='14px!'
                  mx='16px!'
                  key={index}
                >
                  {brand.name}
                </Text>
              )
            })}
          </Stack>
        </Flex>
      </DrawerBody>
    </DrawerContent>
  )
}
export default SelectBrandModelBS
