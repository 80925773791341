import { Button, ButtonProps } from '@chakra-ui/react'
import { MouseEvent } from 'react'

type Props = {
  title: string
  onClick(e?: MouseEvent<HTMLButtonElement>): void
  isDisabled: boolean
  margin?: string
}
const SubmitButton = ({ title, onClick, isDisabled, margin, ...props }: Props & ButtonProps) => {
  return (
    <Button
      {...props}
      m={margin}
      w='full'
      fontSize='18px'
      lineHeight='36px'
      h='48px'
      bg='primary.enable'
      textColor='white'
      onClick={onClick}
      boxShadow='none'
      borderRadius='8px'
      _disabled={{
        bg: 'primary.deactive',
        textColor: 'text.deactive',
      }}
      mt='45px!'
      isDisabled={isDisabled}
    >
      {title}
    </Button>
  )
}
export default SubmitButton
