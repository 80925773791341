import { Box } from '@chakra-ui/react'
import Lottie, { Options } from 'react-lottie'
import loadingAnimation from '../../assets/lottie/farmon-loading.json'

type Props = {
  isFallBack?: boolean
}
const LoadingIndicator = ({ isFallBack }: Props) => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Box
      zIndex='3'
      width='100%'
      height={isFallBack ? '100vh' : 'inherit'}
      display='flex'
      justifyContent='center'
    >
      <Lottie
        style={{
          margin: 'auto',
        }}
        width={300}
        height={300}
        options={defaultOptions}
      />
    </Box>
  )
}
export default LoadingIndicator
