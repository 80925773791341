import { Input, InputProps, useMergeRefs } from '@chakra-ui/react'
import { ChangeEvent, forwardRef, useCallback, useRef, useState } from 'react'

type Props = {
  charLength: number
  initialValue?: string
  onComplete?: () => void
}

const DashedInput = forwardRef<HTMLInputElement, Props & InputProps>(
  ({ charLength, onComplete, initialValue, ...props }: Props & InputProps, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const mergedRef = useMergeRefs(inputRef, ref)
    const [value, setValue] = useState(initialValue ?? '-'.repeat(charLength))

    const changeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      const val = event.target.value.replaceAll('-', '')
      if (val.length > charLength) return
      if (!val.includes('-') && val.length === charLength) {
        onComplete?.()
        if (onComplete) inputRef.current?.blur()
      }
      setValue((prev) => (prev === val ? prev.slice(0, -1) : val))
      props.onChange?.({ ...event, target: { ...event.target, value: val } })
    }, [])

    const focusHandler = useCallback(() => {
      inputRef.current?.setSelectionRange(0, 0)
    }, [])

    return (
      <Input
        ref={mergedRef}
        inputMode='numeric'
        w='min-content'
        _focusVisible={{}}
        border='none'
        padding='0'
        textAlign='center'
        dir='ltr'
        value={value.padEnd(charLength, '-')}
        {...props}
        onChange={changeHandler}
        onFocus={focusHandler}
      />
    )
  },
)

DashedInput.displayName = 'DashedInput'

export default DashedInput
