import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import useAppDispatch from '../hooks/useAppDispatch'
import { useDispatch } from 'react-redux'

export interface AppState {
  appbar: {
    dynamicTitle: string
    pathParams: any
  }
  selectCarProfileModal: {
    state: boolean
  }
  showLoading: boolean
}

const initialState: AppState = {
  appbar: {
    dynamicTitle: '',
    pathParams: {},
  },
  selectCarProfileModal: {
    state: true,
  },
  showLoading: false,
}

const { reducer, actions } = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDynamicAppbarTitle: (state, action: PayloadAction<AppState['appbar']['dynamicTitle']>) => {
      state.appbar.dynamicTitle = action.payload
    },
    setSelectCarProfileModal: (state, action: PayloadAction<AppState['selectCarProfileModal']>) => {
      state.selectCarProfileModal = action.payload
    },
    setLoadingIndicator: (state, action: PayloadAction<AppState['showLoading']>) => {
      state.showLoading = action.payload
    },
    setPathParams: (state, action: PayloadAction<AppState['appbar']['pathParams']>) => {
      state.appbar.pathParams = action.payload
    },
  },
})

export const useSetDynamicTitleAction = () => {
  const dispatch = useAppDispatch()
  return (payload: AppState['appbar']['dynamicTitle']) => {
    dispatch(actions.setDynamicAppbarTitle(payload))
  }
}

export const useSelectCarProfileModalAction = () => {
  const dispatch = useAppDispatch()
  return (payload: AppState['selectCarProfileModal']) => {
    dispatch(actions.setSelectCarProfileModal(payload))
  }
}

export const useLoadingIndicator = () => {
  const dispatch = useAppDispatch()
  return (payload: AppState['showLoading']) => {
    dispatch(actions.setLoadingIndicator(payload))
  }
}

export const useSetPathParams = () => {
  const dispatch = useDispatch()
  return (payload: AppState['appbar']['pathParams']) => {
    dispatch(actions.setPathParams(payload))
  }
}

export const { setDynamicAppbarTitle, setSelectCarProfileModal, setLoadingIndicator } = actions
export default reducer
