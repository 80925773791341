import { NavigationIconProps } from './HomeIcon'

const MotorInspectionIcon = ({ size }: NavigationIconProps) => {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.83004 14.0996C6.92004 14.0996 6.17004 14.8396 6.17004 15.7596V19.6196C6.17004 20.5296 6.91004 21.2796 7.83004 21.2796C8.74004 21.2796 9.49004 20.5396 9.49004 19.6196V15.7596C9.49004 14.8496 8.75004 14.0996 7.83004 14.0996Z'
        fill='#00A754'
      />
      <path
        d='M12.02 9.41922C11.47 8.55922 10.66 7.86922 9.69999 7.47922C10.12 7.08922 10.42 6.57922 10.54 6.00922H12.51C12.81 6.00922 13.06 5.75922 13.06 5.45922C13.06 5.15922 12.81 4.90922 12.51 4.90922H10.54C10.28 3.64922 9.16999 2.69922 7.83999 2.69922C6.50999 2.69922 5.38999 3.64922 5.13999 4.90922H3.17C2.87 4.90922 2.62 5.15922 2.62 5.45922C2.62 5.75922 2.87 6.00922 3.17 6.00922H5.13999C5.25999 6.59922 5.56999 7.10922 5.98999 7.49922C4.19999 8.24922 2.93 10.0292 2.93 12.0792V16.0692C2.93 16.8092 3.54 17.4192 4.28 17.4192H5.07999V15.7592C5.07999 14.2392 6.31999 12.9992 7.83999 12.9992C8.41999 12.9992 8.95999 13.1792 9.39999 13.4892C9.51999 11.7292 10.55 10.2192 12.02 9.41922ZM7.82999 7.10922C6.91999 7.10922 6.16999 6.36922 6.16999 5.44922C6.16999 4.53922 6.90999 3.78922 7.82999 3.78922C8.73999 3.78922 9.48999 4.52922 9.48999 5.44922C9.48999 6.36922 8.74999 7.10922 7.82999 7.10922Z'
        fill='#00A754'
      />
      <path
        d='M21.1299 19.6184L19.7099 18.0884C19.4999 17.8484 19.2099 17.7284 18.9199 17.7384L18.4199 17.1584C19.9499 15.4484 20.0199 12.8384 18.4899 11.0484C16.8299 9.09845 13.8899 8.85845 11.9399 10.5284C11.8799 10.5784 11.8099 10.6384 11.7499 10.6984C9.96995 12.3884 9.79995 15.1884 11.4099 17.0784C12.9399 18.8684 15.5299 19.2084 17.4499 17.9784L17.9499 18.5684C17.8999 18.8584 17.9699 19.1684 18.1699 19.4084L19.5999 20.9384C19.9599 21.3684 20.5899 21.4184 21.0199 21.0484C21.4399 20.6784 21.4899 20.0384 21.1299 19.6184ZM17.6899 14.9884C17.4199 14.9484 17.2299 14.6984 17.2699 14.4284C17.4599 13.1184 16.5399 11.8984 15.2299 11.7084C14.9599 11.6684 14.7699 11.4184 14.8099 11.1484C14.8499 10.8784 15.0999 10.6884 15.3699 10.7284C17.2199 10.9884 18.5199 12.7184 18.2599 14.5684C18.2099 14.8384 17.9599 15.0284 17.6899 14.9884Z'
        fill='#00A754'
      />
    </svg>
  )
}
export default MotorInspectionIcon
