import {
  Button,
  Center,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { strings } from '../../../constants/strings'
import { useNavigate } from 'react-router-dom'
import { stringNormalizers } from '../../../utils/stringNormalizers'
import { routePaths } from '../../../routes/urls'
import { useSetDynamicTitleAction } from '../../../store/slices/appSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useRef } from 'react'

export type InsuranceDataProps = {
  carId: string
  carTitle: string
  onClose(): void
}
const InsuranceAlertBS = () => {
  const pageStrings = strings.carDetail
  const setDynamicAppbar = useSetDynamicTitleAction()
  const navigate = useNavigate()
  const { onClose, carId, carTitle } = useSelector((state: RootState) => state.bottomSheet.data)
  const timeoutRef = useRef<NodeJS.Timeout>()
  return (
    <DrawerContent borderTopRadius='8px' p='24px 16px' pb='0!'>
      <DrawerHeader
        py='0!'
        px='16px'
        justifyContent='space-between'
        w='full'
        display='flex'
        flexDir='row'
      >
        <Text textStyle='titleXSmall' textColor='text.grey-800'>
          {pageStrings.thirdPartyInsuranceBS.title}
        </Text>
        <Center>
          <CloseIcon onClick={onClose} boxSize='3' />
        </Center>
      </DrawerHeader>
      <DrawerBody w='full' px='0!'>
        <VStack mt='24px' justifyContent='space-between' w='full' h='full' flexDir='column'>
          <Text
            borderRadius='8px'
            textStyle='bodySmallSemibold'
            color='error.text'
            bg='error.surface'
            w='full'
            p='8px 16px'
          >
            {pageStrings.thirdPartyInsuranceBS.alertDesc}
          </Text>
          <Text
            my='16px'
            textStyle='bodySmallSemibold'
            textAlign='justify'
            color='text.grey-800'
            w='full'
          >
            {pageStrings.thirdPartyInsuranceBS.mainDesc}
          </Text>
          <HStack my='16px!' w='full' justifyContent='space-between'>
            <Button _hover={{}} _focusVisible={{}} variant='ghost' w='full' onClick={onClose}>
              <Text textStyle='titleXSmall' textColor='illustration.brandgreen'>
                {pageStrings.thirdPartyInsuranceBS.purchaseButton}
              </Text>
            </Button>
            <Button
              onClick={() => {
                if (timeoutRef.current) clearTimeout(timeoutRef.current)
                timeoutRef.current = setTimeout(() => {
                  navigate(stringNormalizers(routePaths.THIRD_PARTY_INSURANCE.url, ':carId', carId))
                }, 100)
                onClose()
                setDynamicAppbar(carTitle)
              }}
              _hover={{}}
              _focusVisible={{}}
              bg='illustration.brandgreen'
              w='full'
            >
              <Text textStyle='titleXSmall' textColor='white'>
                {pageStrings.thirdPartyInsuranceBS.updateButton}
              </Text>
            </Button>
          </HStack>
        </VStack>
      </DrawerBody>
    </DrawerContent>
  )
}
export default InsuranceAlertBS
