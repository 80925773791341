import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

type OptionType = string | number

export const DropdownSelect = ({
  options,
  defaultValue,
  onChange,
}: {
  options: { item: OptionType; value: OptionType }[]
  defaultValue?: { item: OptionType; value: OptionType }
  onChange: (item: OptionType) => void
}) => {
  const [isOpen, setOpen] = useState(false)
  const selectBoxRef = useRef<HTMLDivElement>(null)
  const selectOptionsRef = useRef<HTMLDivElement>(null)
  const [value, setValue] = useState(defaultValue ?? options[0])

  const toggleHandler = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const tapOutHandler = useCallback((event: any) => {
    if (!event.target || !selectOptionsRef.current) return
    if (
      !selectBoxRef.current?.contains(event.target) &&
      !selectOptionsRef.current?.contains(event.target)
    ) {
      setOpen(false)
      event.stopPropagation()
    }
  }, [])

  const selectHandler = useCallback(
    (item: { item: OptionType; value: OptionType }) => {
      setValue(item)
      setOpen(false)
      onChange?.(item.value)
    },
    [onChange],
  )

  useEffect(() => {
    document.addEventListener('click', tapOutHandler, { capture: true })
    return () => {
      document.removeEventListener('click', tapOutHandler, { capture: true })
    }
  }, [])

  return (
    <Flex flexDir='row' pos='relative' zIndex='1' flexGrow='1'>
      <Flex
        ref={selectBoxRef}
        flexDir='row'
        padding='8px 11px'
        bgColor='informative.white-divider'
        borderRadius='6px'
        flexGrow='1'
        alignItems='center'
        justify='space-between'
        onClick={toggleHandler}
      >
        <Text textStyle='titleXSmall'>{value.item}</Text>
        <ChevronDownIcon boxSize='24px' color='illustration.brandgreen' />
      </Flex>
      {isOpen &&
        createPortal(
          <Flex
            ref={selectOptionsRef}
            flexDir='column'
            pos='fixed'
            top={`${(selectBoxRef.current?.getBoundingClientRect().bottom ?? 0) + 16}px`}
            bottom='0'
            left='0'
            right='0'
            bg='surface.default'
            overflow='auto'
            zIndex='10000'
          >
            {options.map((item, index) => {
              const isSelected = value.value === item.value
              return (
                <Flex
                  key={`${item}-${index}`}
                  flexDir='row'
                  w='full'
                  p='14px 16px'
                  bgColor={isSelected ? 'primary.selected' : 'surface.default'}
                  align='center'
                  borderBottom='1px solid'
                  borderColor='background.divider'
                  onClick={() => {
                    selectHandler(item)
                  }}
                >
                  {isSelected && (
                    <CheckIcon boxSize='16px' color='illustration.brandgreen' me='8px' />
                  )}
                  <Text textStyle='captionLargeRegular'>{item.item}</Text>
                </Flex>
              )
            })}
          </Flex>,
          document.body,
        )}
    </Flex>
  )
}
