import axios, { InternalAxiosRequestConfig } from 'axios'
import { getPersistentUserToken } from './userAuthTool'

const BASE_URL = import.meta.env.VITE_API_BASE_URL
const USER_TRACE_URL = import.meta.env.VITE_USER_TRACE_BASE_URL

if (!BASE_URL) throw new Error(`BASE_URL must be set`)
if (!USER_TRACE_URL) throw new Error(`USER_TRACE_URL must be set`)

const providerAxios = axios.create({
  baseURL: BASE_URL,
  timeout: 100000,
  withCredentials: true,
})

const userTraceAxios = axios.create({
  baseURL: USER_TRACE_URL,
  timeout: 100000,
  withCredentials: false,
})

const onRequestFulfilled: (value: any) => any | Promise<any> = (
  config: InternalAxiosRequestConfig,
) => {
  const token = getPersistentUserToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

const onRequestRejected: (error: unknown) => unknown = (error: unknown) => Promise.reject(error)

providerAxios.interceptors.request.use(onRequestFulfilled, onRequestRejected)

export { providerAxios, userTraceAxios }
