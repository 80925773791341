import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import { ChakraBaseProvider } from '@chakra-ui/react'
import { store } from './store/store'
import * as Sentry from '@sentry/browser'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './utils/queryClient'
import Fonts from './components/theme-components/Fonts'
import theme from './theme/theme'
import { RtlProvider } from './components/theme-components/RtlProvider'
import InterceptorComponent from './components/InterceptorComponent'
import posthog from 'posthog-js'
import { ReactQueryDevtools } from 'react-query/devtools'
import UtmParamsProvider from './providers/UtmParamsProvider'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { registerSW } from 'virtual:pwa-register'

Sentry.init({
  dsn: 'https://8f7993e2e0f8497b8ce5461eaa0481f6@kar-sentry.karnameh.com/6',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
})

posthog.init('phc_vzI0qegkgnZtLfjouXeTyVNRE1TU5Vz7dTMfAHXf9SZ', {
  api_host: 'https://us.posthog.com',
  autocapture: false,
  capture_pageview: false,
  capture_pageleave: false,
})

const intervalMS = 60 * 60 * 1000
registerSW({
  immediate: true,
  onRegisteredSW: (registration: ServiceWorkerRegistration | undefined) => {
    registration &&
      setInterval(() => {
        registration.update()
      }, intervalMS)
  },
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <UtmParamsProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <ChakraBaseProvider theme={theme}>
              <Fonts />
              <RtlProvider>
                <InterceptorComponent />
                <App />
              </RtlProvider>
            </ChakraBaseProvider>
          </QueryClientProvider>
        </UtmParamsProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
)
