export const strings = {
  carDetail: {
    menu: {
      edit: 'ویرایش اسم ماشین',
      favorite: 'انتخاب به عنوان تصویر اصلی',
      delete: 'حذف تصویر',
      deleteCarProfile: 'حذف خودرو',
    },
    completed: 'کامل شده',
    uncompleted: 'تکمیل نشده',
    complete: 'تکمیل اطلاعات',
    completeDescription: 'با تکمیل اطلاعات ماشینتو طلایی کن!',
    baseInfo: 'اطلاعات پایه',
    baseInfoDescription: 'برند(سیستم)، مدل(سال تولید)، تیپ، رنگ، پلاک، شماره VIN',
    estimate: 'اطلاعات تخمین قیمت',
    estimateDescription: 'کارکرد، وضعیت بدنه',
    carReportDescription: 'گارانتی، کارشناسی کارنامه',
    insurance: 'بیمه شخص ثالث',
    bodyInsurance: 'بیمه بدنه',
    bodyInsuranceDescription: 'مهلت و تاریخ اعتبار، شرکت بیمه کننده، تخفیف',
    technicalDiagnosis: 'معاینه فنی',
    ownerInfo: 'اطلاعات مالک',
    ownerInfoDescription: 'نام مالک، شماره ملی،',
    remainedTime: 'مهلت و اعتبار',
    remainedTimeAndDate: 'مهلت و تاریخ اعتبار',
    insuranceCompany: 'شرکت بیمه کننده، تخفیف',
    inspectionPhoto: 'تصویر معاینه فنی',
    thirdPartyInsuranceBS: {
      title: 'اتمام تاریخ بیمه شخص ثالث',
      alertDesc: 'تردد بدون بیمه شخص ثالث باعث توقیف و جریمه غیر قابل بخشش ماشین شما می‌شود.',
      mainDesc:
        'میتونی بیمه شخص ثالث رو به صورت آنلاین خریداری بکنی و اگه از قبل خریداری و تمدیدش کردی، تاریخش رو اینجا هم بروز‌رسانی کن.',
      purchaseButton: 'خرید آنلاین بیمه',
      updateButton: 'بروز‌رسانی تاریخ',
    },
  },
  basicInfo: {
    system: 'سیستم (برند)',
    model: 'مدل (سال تولید)',
    color: 'رنگ',
    plate: 'پلاک',
    vin: 'شماره VIN',
    chassis: 'شماره شاسی',
    editColor: 'ویرایش رنگ خودرو',
    vinInfoText: 'VIN ، 17 رقم است.',
    submit: 'ثبت اطلاعات',
  },
  phoneNumber: 'شماره موبایل',
  expireDate: 'انقضای تاریخ',
  notAvailable: 'وارد نشده',
  imageSelector: { chooseNewPicture: 'انتخاب تصویر جدید', camera: 'دوربین', gallery: 'گالری' },
  carProfileCard: {
    fillCarInfo: 'با ثبت مشخصات ماشینت، قیمت حدودیش رو ببین!',
    priceEstimationError: 'در حال حاظر قادر به محاسبه قیمت خودرو شما نیستیم!',
    reminders: 'یادآورها',
    emptyReminders: 'جهت استفاده از این قسمت، ابتدا اطلاعات خودروی خود را تکمیل نمایید.',
    updateReminderButton: 'تکمیل اطلاعات',
    updateStatus: 'بروز‌رسانی وضعیت',
    soon: 'به‌زودی',
    new: 'جدید',
    free: 'رایگان',
    addYourCarHere: 'جای ماشینت اینجا خالیه!',
    addNewCarTitle: 'اضافه کردن ماشین جدید',
  },
  carReportPage: {
    pageTitle: 'کارنامه ماشین',
    guaranteeDuration: 'مهلت گارانتی کارنامه',
    inspectionDate: 'کارشناسی کارنامه',
    fullReportButton: 'نمایش گزارش کامل',
  },
  ownerInfoPage: {
    title: 'اطلاعات مالک',
    incompleteInfo: 'تکمیل نشده',
    owner_name: 'نام مالک',
    owner_national_id: 'شماره ملی',
    owner_phone_number: 'شماره موبایل',
    add_name_bs_title: 'وارد کردن نام مالک',
    add_id_bs_title: 'وارد کردن شماره ملی مالک',
    add_phone_bs_title: 'وارد کردن شماره همراه مالک',
  },
  plateBottomSheet: {
    title: 'وارد کردن پلاک',
    description: 'پلاک ماشین خود را وارد کنید.',
    iran: 'ایران',
    submit: 'ثبت پلاک',
  },
  thirdPartyInsurancePage: {
    insuranceDate: 'تاریخ بیمه شخص ثالث',
    expired: 'منقضی شده',
    insuranceDuration: 'مهلت بیمه شخص ثالث',
    discount: 'تخفیف بیمه شخص ثالث',
    agent: 'شرکت بیمه کننده',
    enter: 'وارد کردن تاریخ بیمه شخص ثالت',
  },
  confirmBS: {
    title: 'تایید اطلاعات',
    description: 'آیا اطلاعات ثبت شده  را تایید می‌کنید؟',
    notifier: 'توجه داشته باشید اطلاعات پس از تایید دیگر قابل ویرایش نیست.',
    confirm: 'تایید نهایی',
    cancel: 'ویرایش اطلاعات',
    toastTitle: 'اطلاعات پایه شما با موفقیت ثبت شد.',
  },
  onlineConsultant: {
    noAccess: {
      title: 'شما به این سرویس دسترسی ندارد.',
      description:
        'در حال حاضر فقط افرادی که از خدمات کارشناسی یا دستیار خرید کارنامه استفاده کرده‌اند‌، می‌توانند از این سرویس استفاده کنند.',
    },
    title:
      'با استفاده از این سرویس شما می‌توانید یک مشاوره تلفنی با یکی از کارشناسان کارنامه داشته باشید.',
    startCall: 'پرداخت ۲۵,۰۰۰ تومان و ثبت درخواست',
  },
  guarantee: {
    description:
      'در زمان فعال بودن گارانتی کارنامه، در صورت مشاهده مغایرت بین گزارش کارشناسی و قسمت بدنه، آن را ثبت کنید تا با شما تماس بگیریم.',
    report: 'گزارش مغایرت',
    submit: 'ثبت مغایرت',
    reportTitle: 'مغایرت مشاهده شده را ثبت کنید.',
  },
  bodyInsurance: {
    title: 'بیمه بدنه',
    enterDate: 'ورود تاریخ بیمه بدنه',
    insuranceDate: 'تاریخ بیمه بدنه',
    remainedTime: 'مهلت بیمه بدنه',
    insuranceDiscount: 'تخفیف بیمه بدنه',
    enterDiscount: 'وارد کردن تخفیف بیمه',
    discountInputTitle: 'تخفیف بیمه (سال)',
    insuranceCompany: 'شرکت بیمه کننده',
    submit: 'ثبت و ادامه',
    updateData: 'بروز‌‌رسانی اطلاعات',
  },
  confirm: 'تایید',
  cancel: 'انصراف',
  technicalDiagnosisPage: {
    title: 'معاینه فنی',
    heading: 'تاریخ معاینه فنی جدیدت رو اینجا وارد کن.',
    datePickerTitle: 'تاریخ معاینه فنی',
    newDatePickerTitle: 'تاریخ معاینه فنی جدید',
    uploadFileInfoText: 'در صورت امکان برگه معاینه فنی ماشینت رو اینجا بارگذاری کن.',
    uploadFileButton: 'بارگذاری تصویر معاینه فنی',
    submitButton: 'ثبت اطلاعات',
    imageBaseUrl: 'https://s3.thr2.sotoon.ir/karnameh-mycar-backend/',
    expiredTitle: 'انقضای تاریخ',
  },
  submit: 'ثبت',
  reports: {
    title: 'کارنامه ماشین',
    servicesTitle: 'گزارش سرویس دوره‌ای',
    inspectionTitle: 'گزارش سرویس دوره‌ای',
  },
  servicesReport: {
    date: 'تاریخ انجام سرویس',
    mileage: 'کارکرد (km)',
    tier: 'نوع بسته',
    price: 'هزینه',
    code: 'کد رهگیری',
    detail: 'مشاهده جزییات',
  },
  consultancySubmission: {
    success: {
      heading: 'پرداخت شما با موفقیت انجام شد.',
      subtitle: 'کارشناسان ما به زودی با شما تماس خواهند گرفت.',
      button: 'بازگشت به صفحه اصلی',
    },
    failed: {
      heading: 'پرداخت شما ناموفق بود.',
      subtitle: '',
      button: 'تلاش مجدد برای پرداخت',
    },
  },
  completionRate: {
    title: 'میزان تکمیل اطلاعات',
    emptyTitle: 'تکمیل اطلاعات خودروی من',
    subtitle: 'استفاده از خدمات و یادآورها با تکمیل اطلاعات',
    button: 'مشاهده',
  },
  lastMileage: {
    title: 'آخرین کارکرد:',
    subtitle: 'کارکرد تخمینی حاضر',
    suffix: 'km',
    unknown: 'اطلاع از آخرین وضعیت خودرو',
  },
  violation: {
    title: 'خلافی خودرو:',
    subtitle: 'بروز‌رسانی',
    noViolation: 'بدون استعلام',
    hasViolation: 'استعلام شده',
    unknown: 'استعلام و پرداخت خلافی ',
  },
  monthlyMileage: {
    title: 'تخمین کارکرد ماهانه:',
    subtitle: 'بروز‌رسانی:',
    suffix: 'km',
    unknown: 'استفاده از خدمات و یادآورها',
  },
  latestPeriodicService: {
    title: 'آخرین سرویس دوره‌ای:',
    subtitle: 'تاریخ انجام:',
    suffix: 'km',
    cta: 'استفاده از خدمات سرویس دوره‌ای',
  },
  lastAfterMarket: {
    title: 'آخرین سرویس دوره‌ای:',
    subtitle: 'تاریخ انجام:',
    suffix: 'km',
  },
  priceEstimate: {
    title: 'حدود قیمت :',
    suffix: 'تومان',
    unchanged: 'بدون تغییر قیمت در 10 روز گذشته',
    unknown: 'اطلاع از قیمت روز خودرو با ثبت اطلاعات',
    unavailable: 'عدم امکان ارائه قیمت',
    loading: 'در حال دریافت...',
    subtitle: 'مشاهده تغییرات قیمت',
    tenDaysChange: 'تغییر در 10 روز گذشته',
  },
}
