import { NavigationIconProps } from '@src/components/icons/HomeIcon'

const PriceHistoryIcon = ({ size }: NavigationIconProps) => {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.72 13.5703C16.55 13.5703 16.38 13.5703 16.21 13.6003C13.93 13.8503 12.16 15.7703 12.16 18.1303C12.16 18.4203 12.19 18.7103 12.24 18.9903C12.64 21.1003 14.49 22.6903 16.72 22.6903C19.24 22.6903 21.28 20.6603 21.28 18.1303C21.28 15.6003 19.25 13.5703 16.72 13.5703ZM18.68 19.4903C18.66 20.0403 18.37 20.4203 17.88 20.6703C17.68 20.7703 17.46 20.8403 17.23 20.8703C17.18 20.8703 17.16 20.9003 17.16 20.9503C17.16 21.1403 17.16 21.3303 17.16 21.5203V21.6103H16.22C16.22 21.6103 16.22 21.5603 16.22 21.5303C16.22 21.3403 16.22 21.1403 16.22 20.9503C16.22 20.8903 16.2 20.8703 16.14 20.8603C15.67 20.7603 15.25 20.5603 14.89 20.2303L14.84 20.1803C14.84 20.1803 14.84 20.1703 14.82 20.1603C14.93 20.0603 15.03 19.9603 15.14 19.8603C15.24 19.7703 15.35 19.6703 15.45 19.5803C15.49 19.5403 15.51 19.5503 15.54 19.5803C16.06 20.0103 16.65 20.1103 17.3 19.9303C17.34 19.9203 17.37 19.9103 17.4 19.8903C17.79 19.7103 17.85 19.2403 17.52 18.9703C17.36 18.8403 17.16 18.7703 16.96 18.7003C16.62 18.5903 16.27 18.4903 15.94 18.3703C15.66 18.2703 15.41 18.1203 15.2 17.9003C14.71 17.3803 14.65 16.3003 15.54 15.7803C15.72 15.6703 15.92 15.6003 16.12 15.5503C16.18 15.5303 16.21 15.5103 16.21 15.4403C16.21 15.2403 16.21 15.0403 16.21 14.8503V14.7603H17.15V15.4503C17.38 15.5003 17.59 15.5403 17.8 15.6003C18.01 15.6603 18.21 15.7503 18.4 15.8703C18.38 15.9103 18.36 15.9303 18.34 15.9603C18.2 16.1703 18.07 16.3803 17.93 16.5803C17.91 16.6203 17.89 16.6303 17.84 16.6103C17.36 16.3603 16.85 16.3103 16.32 16.4403C16.18 16.4703 16.06 16.5303 15.95 16.6203C15.73 16.8103 15.74 17.1603 15.97 17.3403C16.13 17.4703 16.33 17.5403 16.53 17.6003C16.88 17.7103 17.23 17.8203 17.58 17.9403C17.87 18.0503 18.12 18.2103 18.32 18.4303C18.59 18.7303 18.69 19.0903 18.68 19.4803V19.4903Z'
        fill='#00A754'
      />
      <path
        d='M14.13 1.31055H4.78997C3.63997 1.31055 2.71997 2.24055 2.71997 3.38055V16.9105C2.71997 18.0505 3.63997 18.9805 4.78997 18.9805H11.12C11.08 18.7005 11.06 18.4105 11.06 18.1205C11.06 15.1805 13.32 12.7505 16.2 12.4905V3.38055C16.2 2.24055 15.28 1.31055 14.13 1.31055ZM5.48997 12.5605C5.17997 12.5605 4.93997 12.3205 4.93997 12.0105C4.93997 11.7005 5.17997 11.4605 5.48997 11.4605H11.37C11.67 11.4605 11.92 11.7105 11.92 12.0105C11.92 12.3105 11.67 12.5605 11.37 12.5605H5.48997ZM4.93997 15.3405C4.93997 15.0405 5.17997 14.8005 5.48997 14.8005H8.61997C8.92997 14.8005 9.16997 15.0405 9.16997 15.3405C9.16997 15.6405 8.92997 15.8905 8.61997 15.8905H5.48997C5.17997 15.8905 4.93997 15.6505 4.93997 15.3405ZM5.22997 8.48055L7.57997 6.09055L9.33997 7.79055L12.18 4.95055H11.4C11.1 4.95055 10.85 4.71055 10.85 4.40055C10.85 4.09055 11.1 3.85055 11.4 3.85055H14.01V6.34055C14.01 6.64055 13.77 6.89055 13.46 6.89055C13.15 6.89055 12.91 6.64055 12.91 6.34055V5.77055L9.34997 9.34055L7.59997 7.64055L6.01997 9.25055C5.90997 9.36055 5.76997 9.42055 5.61997 9.42055C5.47997 9.42055 5.34997 9.36055 5.23997 9.26055C5.01997 9.04055 5.01997 8.70055 5.22997 8.48055Z'
        fill='#00A754'
      />
    </svg>
  )
}
export default PriceHistoryIcon
