import { Icon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { IoMdExit } from 'react-icons/io'
import { MdPerson2, MdPersonOutline } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import { useLogoutUserMutation } from '../../../api/auth/Auth.api'
import { routePaths } from '../../../routes/urls'
import { clearCredentialCookies } from '../../../utils/cookieManager'
import { clearCredentialsFromLS } from '../../../utils/localStorageManager'
import { getUserDataFromToken, userIsLoggedIn } from '../../../utils/userAuthTool'
import { ProfileMenuInterface } from './data'

const AppbarProfile = ({ list }: { list: ProfileMenuInterface[] }) => {
  const isLoggedIn = userIsLoggedIn()
  const jwtData = getUserDataFromToken()
  const navigator = useNavigate()
  const logoutUser = useLogoutUserMutation()

  const logout = () => {
    clearCredentialCookies()
    clearCredentialsFromLS()
    logoutUser.mutate(undefined, {
      onSuccess: () => {
        navigator(routePaths.AUTH.url, { replace: true })
      },
    })
  }

  return (
    <Menu autoSelect={false}>
      <MenuButton
        id='basic-button'
        padding={0}
        display='flex'
        flexDirection='row'
        justifyContent='center'
        gap='.5rem'
        alignItems='center'
      >
        <HStack justifyContent='center' spacing='.5rem' alignItems='center'>
          <Box position='relative' display='flex' justifyContent='center' alignItems='center'>
            {isLoggedIn && (
              <Box
                top='0'
                left='1'
                w='8px'
                h='8px'
                position='absolute'
                borderRadius='99rem'
                bg='success.text'
              />
            )}
            <Icon as={MdPersonOutline} width='24px' height='24px' color='rgba(0, 0, 0, 0.56)' />
          </Box>
          <Box fontSize='.75rem' fontWeight='600' lineHeight='2' color='rgba(0,0,0,.56)'>
            {isLoggedIn ? 'حساب کاربری' : 'ورود / ثبت‌نام'}
          </Box>
        </HStack>
      </MenuButton>
      {isLoggedIn && (
        <MenuList>
          <MenuItem>
            <Link flexDirection={'column'} _hover={{}} href={'/profile/cars'}>
              <Box alignItems={'center'} display={'flex'} gap='0.5rem'>
                <Icon as={MdPerson2} width='20px' height='20px' color='text.secondary' />
                <Text fontSize={'0.875rem'} fontWeight={'500'} color='text.secondary'>
                  کاربر کارنامه
                </Text>
              </Box>
              <Box
                alignItems={'center'}
                display={'flex'}
                color={'rgba(0,0,0,.32)'}
                marginTop={'4px'}
                marginLeft={'28px'}
                fontSize='0.75rem'
              >
                <Text>{jwtData?.username}</Text>
              </Box>
            </Link>
          </MenuItem>
          <Divider mb='0.3rem' />
          {list?.map((item) => {
            const Icon = item.icon
            return (
              <MenuItem gap='0.5rem' key={item.id} mb='0.2rem'>
                <Link
                  href={item.link}
                  display='flex'
                  flexDirection='row'
                  justifyContent='center'
                  gap='.5rem'
                  textDecoration='none'
                  alignItems='center'
                  _hover={{}}
                >
                  <Stack
                    sx={{
                      width: '2.5rem',
                      height: '2.5rem',
                      backgroundColor: '#EBF8F1',
                      borderRadius: '0.5rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon type='component' key={`icon${item.id}`} />
                  </Stack>
                  <Text
                    fontSize='0.75rem'
                    fontStyle='normal'
                    fontWeight='400'
                    lineHeight='1.875rem'
                    color='text.secondary'
                    _hover={{ color: 'black' }}
                  >
                    {item.title}
                  </Text>
                </Link>
              </MenuItem>
            )
          })}
          <Divider mb='0.3rem' />

          <MenuItem gap='0.5rem' h='1.875rem' minH='48px'>
            <Link
              w='full'
              onClick={logout}
              display='flex'
              flexDirection='row'
              justifyContent='start'
              alignItems='start'
              gap='.5rem'
              textDecoration='none'
              _hover={{}}
            >
              <Icon as={IoMdExit} width='20px' height='20px' color='text.secondary' />
              <Text
                fontSize='0.75rem'
                fontStyle='normal'
                fontWeight='400'
                lineHeight='1.875rem'
                color='text.secondary'
                _hover={{ color: 'black' }}
              >
                خروج
              </Text>
            </Link>
          </MenuItem>
        </MenuList>
      )}
    </Menu>
  )
}

export default AppbarProfile
