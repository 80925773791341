import { DrawerContent, Flex, Text } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import PriceEstimator from '@src/components/car-estimation/price-estimator'
import { BottomSheetHeader } from '@src/components/common/bottomsheet/BottomSheetHeader'
import { useCloseBottomSheet } from '@src/store/slices/bottomSheetSlice'
import { RootState } from '@src/store/store'

export type CarEstimationBSProps = {
  title: string
  carProfileId: string | number
  onClose: () => void
}

export const CarEstimationBS = () => {
  const closeBS = useCloseBottomSheet()
  const { title, carProfileId } = useSelector(
    (state: RootState): CarEstimationBSProps => state.bottomSheet.data,
  )

  const closeHandler = useCallback(() => {
    closeBS()
  }, [])

  return (
    <DrawerContent borderRadius='8px 8px 0 0'>
      <BottomSheetHeader title={title} onClose={closeHandler} />
      <Flex justifyContent={'center'} px={4} direction={'column'}>
        <Text mb={6} fontSize={14} fontWeight={400} textAlign={'center'}>
          حدود قیمت، میانگین حداکثر و حداقل قیمت است.
        </Text>
        <PriceEstimator carProfileId={carProfileId} />
      </Flex>
    </DrawerContent>
  )
}
