import {
  Button,
  Center,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { CloseIcon, Icon } from '@chakra-ui/icons'
import { IoCloseCircle } from 'react-icons/io5'
import * as yup from 'yup'
import { string } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useUpdateCurrentUser } from '../../../api/user/User.api'
import { MdCheckCircle } from 'react-icons/md'
import { createStandaloneToast } from '@chakra-ui/toast'

type EditPhoneNumberType = {
  phoneNumber: string
}

type DataProps = {
  phoneNumber: string
  onClose(): void
}
const EditPhoneNumberBottomSheet = () => {
  const { phoneNumber, onClose } = useSelector(
    (state: RootState): DataProps => state.bottomSheet.data,
  )
  const editPhoneNumberSchema = yup.object().shape({
    phoneNumber: string().required('لطفا شماره تماس را وارد کنید'),
  })

  const { register, setValue, watch, handleSubmit } = useForm<EditPhoneNumberType>({
    resolver: yupResolver(editPhoneNumberSchema),
    mode: 'all',
    defaultValues: {
      phoneNumber: phoneNumber,
    },
  })

  const { toast } = createStandaloneToast()

  const editUserDetails = useUpdateCurrentUser({
    onSuccess: () => {
      toast({
        title: 'شماره تماس با موفقیت تغییر کرد.',
        position: 'top',
        status: 'success',
        variant: 'actionSuccess',
        duration: 2000,
        icon: (
          <Center>
            <Icon size='16px' as={MdCheckCircle} color='success.icon' />
          </Center>
        ),
      })
      onClose()
    },
  })

  function onSubmit(data: EditPhoneNumberType) {
    editUserDetails.mutate({
      phone_number: data.phoneNumber,
    })
  }

  return (
    <DrawerContent borderTopRadius='8px' pt='24px' pb='0!' px='16px'>
      <DrawerHeader p='0!' justifyContent='space-between' w='full' display='flex' flexDir='row'>
        <Text textStyle='titleXSmall' textColor='text.grey-800'>
          ویرایش نام و نام خانوادگی
        </Text>
        <Center>
          <CloseIcon onClick={onClose} boxSize='3' />
        </Center>
      </DrawerHeader>
      <DrawerBody pt='12px' pb='24px' px='0px' w='full'>
        <form id='username-edit-form' onSubmit={handleSubmit(onSubmit)}>
          <FormControl variant='floating-icon' id='karnameh-app-firs-name' mt='24px'>
            <InputGroup>
              <Input
                required={false}
                type='text'
                py='10px!'
                px='16px'
                outline='0px'
                border='1px'
                _focusVisible={{
                  border: 'none',
                }}
                borderColor={!watch('phoneNumber') ? 'error.icon' : 'text.deactive'}
                _focus={{
                  outline: 'none',
                  border: '1px solid',
                  borderColor: !watch('phoneNumber') ? 'error.icon' : 'accent.main',
                }}
                _placeholder={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '32px',
                  color: 'text.primary',
                }}
                {...register('phoneNumber')}
              />
              <InputRightElement onClick={() => setValue('phoneNumber', '')}>
                <Icon boxSize='24px' as={IoCloseCircle} color='text.deactive' />
              </InputRightElement>
            </InputGroup>

            {/* It is important that the Label comes after the Control due to css selectors */}
            <FormLabel
              _focus={{
                textColor: 'accent.enable',
              }}
              textStyle='captionLargeRegular'
              textColor='text.deactive'
              backgroundColor='white'
            >
              شماره شبای جدید
            </FormLabel>
          </FormControl>
        </form>
      </DrawerBody>
      <DrawerFooter pb='24px' px='0!' w='full'>
        <Button
          bg='illustration.brandgreen'
          _hover={{
            bg: 'illustration.brandgreen',
          }}
          _disabled={{
            bg: 'primary.deactive',
          }}
          form='username-edit-form'
          type='submit'
          w='full'
        >
          ثبت
        </Button>
      </DrawerFooter>
    </DrawerContent>
  )
}
export default EditPhoneNumberBottomSheet
