import { Icon } from "@chakra-ui/react"
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { stringNormalizers } from "../../../utils/stringNormalizers";
import { routePaths } from "../../../routes/urls";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const EditPeriodicServiceIcon = () => {
    const { carId, serviceId } = useSelector((state: RootState) => state.app.appbar.pathParams)

    const navigate = useNavigate()
    const updatePath = stringNormalizers(
        stringNormalizers(routePaths.UPDATE_PERIODIC_SERVICE.path, ":serviceId", serviceId ?? ""),
        ":carId",
        carId ?? "",
    )

    return <Icon
        onClick={() => navigate(updatePath)}
        as={MdEdit}
        boxSize='1.5rem'
        color='icon.default'
    />
}

export default EditPeriodicServiceIcon