import { configureStore } from '@reduxjs/toolkit'
import appReducer from './slices/appSlice'
import { BottomSheetReducer } from './slices/bottomSheetSlice'
import profileReducer from './slices/profileSlice'

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    app: appReducer,
    bottomSheet: BottomSheetReducer,
    profile: profileReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
// adding this to configureStore cause redux ignore non-serializable check
