import { ReactNode } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Text, VStack } from '@chakra-ui/react'
import { useLoadingIndicator } from '../../../store/slices/appSlice'

type Props = {
  path: string
  activeIcon: ReactNode
  deActiveIcon: ReactNode
  title: string
}
const CustomizedNavLink = ({ path, title, activeIcon, deActiveIcon }: Props) => {
  const showGlobalLoading = useLoadingIndicator()
  const { pathname } = useLocation()

  return (
    <NavLink
      onClick={() => {
        if (path !== pathname) {
          showGlobalLoading(true)
        }
      }}
      to={path}
    >
      {({ isActive }) => (
        <VStack gap='0' color='icon.default' justifyContent='center'>
          <VStack
            justifyContent='center'
            alignItems='center'
            w='4rem'
            h='2rem'
            borderRadius='1rem'
            bg={isActive ? 'accent.selected' : 'transparent'}
          >
            {isActive ? activeIcon : deActiveIcon}
          </VStack>
          <Text
            mt='0.25rem'
            textAlign='center'
            textStyle='navigationTitle'
            color={isActive ? 'text.primary' : 'icon.default'}
          >
            {title}
          </Text>
        </VStack>
      )}
    </NavLink>
  )
}
export default CustomizedNavLink
