import { ACCESS_TOKEN, DEVICE_ID, FCM_TOKEN, INSPECTION_REPORT } from './consts'
import { InspectionReportDTO } from '../api/car-profile/CarProfile.dto'

export const setInspectionReportToLocalStorage = (value: string) => {
  localStorage.setItem(INSPECTION_REPORT, value)
}

export const getInspectionReportValueFromLocalStorage = () => {
  const report = localStorage.getItem(INSPECTION_REPORT)
  if (!report || report === 'undefined') return undefined
  return JSON.parse(report) as InspectionReportDTO
}

export const clearCredentialsFromLS = () => {
  localStorage.removeItem(FCM_TOKEN)
  localStorage.removeItem(DEVICE_ID)
  localStorage.removeItem(ACCESS_TOKEN)
}
