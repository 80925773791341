import { CarEstimationBS } from '@src/components/car-estimation/bottomSheet/carEstimationBS'

import SelectBrandModelBS from '../add-new-car/bottom-sheets/SelectBrandModelBS'
import SelectProductionYear from '../add-new-car/bottom-sheets/SelectProductionYear'
import { ConfirmBottomSheet } from '../car-detail/bottomsheets/ConfirmBottomSheet'
import InsuranceAlertBS from '../car-detail/bottomsheets/InsuranceAlertBS'
import { PlateBottomSheet } from '../car-detail/bottomsheets/PlateBottomSheet'
import CropperBS from '../common/CropperBS'
import { ImageSelectorBottomSheet } from '../common/ImageSelectorBottomSheet'
import { DatePickerBottomSheet } from '../common/bottomsheet/DatePickerBottomSheet'
import { PromptBottomSheet } from '../common/bottomsheet/PromptBottomSheet'
import { SelectBottomSheet } from '../common/bottomsheet/SelectBottomSheet'
import { OrderDetailBS } from '../orders/OrderDetailBS'
import EditPhoneNumberBottomSheet from '../profile/bottom-sheets/EditPhoneNumberBottomSheet'
import EditUsernameBottomSheet from '../profile/bottom-sheets/EditUsernameBottomSheet'
import RadioButtonSheet from './RadioButtonSheet'

export type BottomSheetContentType =
  | 'SELECTED_BRAND'
  | 'SELECT_PRODUCTION_YEAR'
  | 'CROPPER'
  | 'IMAGE_SELECTOR'
  | 'EDIT_PHONE_NUMBER'
  | 'EDIT_USERNAME'
  | 'PROMPT'
  | 'PLATE_MODIFICATION'
  | 'SELECT'
  | 'THIRD_PARTY_INSURANCE'
  | 'CONFIRM'
  | 'DATE_PICKER'
  | 'ORDER_DETAIL'
  | 'RADIO'
  | 'CAR_ESTIMATION'

export const BottomSheetsContent: {
  [key in BottomSheetContentType]: () => JSX.Element
} = {
  SELECTED_BRAND: SelectBrandModelBS,
  SELECT_PRODUCTION_YEAR: SelectProductionYear,
  CROPPER: CropperBS,
  IMAGE_SELECTOR: ImageSelectorBottomSheet,
  EDIT_PHONE_NUMBER: EditPhoneNumberBottomSheet,
  EDIT_USERNAME: EditUsernameBottomSheet,
  PROMPT: PromptBottomSheet,
  PLATE_MODIFICATION: PlateBottomSheet,
  SELECT: SelectBottomSheet,
  THIRD_PARTY_INSURANCE: InsuranceAlertBS,
  CONFIRM: ConfirmBottomSheet,
  DATE_PICKER: DatePickerBottomSheet,
  ORDER_DETAIL: OrderDetailBS,
  RADIO: RadioButtonSheet,
  CAR_ESTIMATION: CarEstimationBS,
}
