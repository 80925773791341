import { buildApiMutationHook } from '../../utils/api'
import {
  CheckOtpParams,
  CheckOtpResponseDTO,
  RequestOTPParams,
  RequestOtpResponseDTO,
} from './Auth.dto'
import { CHECK_OTP, LOGOUT_API, REFRESH_TOKEN, SEND_OTP } from '../endpoints'
import { providerAxios } from '../../utils/base.api'
import { queryClient } from '../../utils/queryClient'

export const useAuthRequestOtpMutation = buildApiMutationHook<
  RequestOTPParams,
  RequestOtpResponseDTO
>((params) => providerAxios.post(SEND_OTP, params))

export const useCheckOtpMutation = buildApiMutationHook<CheckOtpParams, CheckOtpResponseDTO>(
  (params) => providerAxios.post(CHECK_OTP, params),
)

export const useLogoutUserMutation = buildApiMutationHook(
  () => providerAxios.post(LOGOUT_API),
  (options) => ({
    ...options,
    onSuccess: (response, params, other) => {
      queryClient.invalidateQueries('current-user')
      options?.onSuccess?.(response, params, other)
    },
  }),
)

export const useRefreshTokenMutation = buildApiMutationHook<void, CheckOtpResponseDTO>(
  () => providerAxios.post<CheckOtpResponseDTO>(REFRESH_TOKEN),
  (options) => ({
    ...options,
    onSuccess: (response, params, other) => {
      options?.onSuccess?.(response, params, other)
    },
  }),
)
