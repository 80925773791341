import { useCallback, useRef } from 'react'
import { DrawerContent, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { FileWithPreview, ImageInput } from './ImageInput'
import { MdClose } from 'react-icons/md'
import GalleryIcon from '../../assets/svg/gallery.svg'
import CameraIcon from '../../assets/svg/camera.svg'
import { strings } from '../../constants/strings'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

const ImageUploadOption = (props: {
  title: string
  icon: string
  fileChangeHandler: (image: FileWithPreview) => void
  onClick?: (event: React.MouseEvent) => void
  isCamera?: boolean
}) => {
  const ref = useRef<HTMLInputElement>(null)

  const clickHandler = useCallback((event: React.MouseEvent) => {
    ref.current?.click()
    props.onClick?.(event)
  }, [])

  return (
    <Flex direction='column' align='center' onClick={clickHandler}>
      <Flex
        direction='row'
        justify='center'
        align='center'
        borderRadius='50%'
        border='1px solid'
        borderColor='text.green'
        w='56px'
        height='56px'
        gap='50px'
      >
        <Image src={props.icon} alt='gallery icon' />
      </Flex>
      <Text textStyle='bodySmall' mt='8px'>
        {props.title}
      </Text>
      <ImageInput
        ref={ref}
        captureMode={Boolean(props.isCamera)}
        changeHandler={props.fileChangeHandler}
      />
    </Flex>
  )
}

export type ImagePickerDataProps = {
  onClose(): void
  onImageSelect(file: FileWithPreview): void
}
export const ImageSelectorBottomSheet = () => {
  const { onClose, onImageSelect } = useSelector(
    (state: RootState): ImagePickerDataProps => state.bottomSheet.data,
  )
  return (
    <DrawerContent px='16px' py='24px' borderTopRadius='8px'>
      <Flex direction='row' justify='space-between' mb='24px'>
        <Text textStyle='cardTitle'>{strings.imageSelector.chooseNewPicture}</Text>
        <Icon as={MdClose} color='#424242' w='24px' height='24px' onClick={onClose} />
      </Flex>
      <Flex direction='row' justify='center' gap='50px'>
        <ImageUploadOption
          title={strings.imageSelector.camera}
          icon={CameraIcon}
          isCamera
          fileChangeHandler={onImageSelect}
        />
        <ImageUploadOption
          title={strings.imageSelector.gallery}
          icon={GalleryIcon}
          fileChangeHandler={onImageSelect}
        />
      </Flex>
    </DrawerContent>
  )
}
