import { Box, Button, DrawerBody, DrawerContent, DrawerFooter } from '@chakra-ui/react'
import EasyCrop from 'react-easy-crop'
import { useCallback, useState } from 'react'
import { Area } from 'react-easy-crop/types'
import getCroppedImg from '../../utils/ImageUtils'
import { FileWithPreview } from './ImageInput'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

type DataProps = {
  onClose(): void
  selectedFile: FileWithPreview | undefined
  submitCrop(file: File): void
}
const CropperBS = () => {
  const { onClose, selectedFile, submitCrop } = useSelector(
    (state: RootState): DataProps => state.bottomSheet.data,
  )
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedImg, setCroppedImg] = useState<Blob | undefined>()

  const handleClick = useCallback(() => {
    if (croppedImg) {
      const newImage = new File(
        [croppedImg],
        `car-picture-${Math.ceil(Math.random() * 1000000000)}.jpeg`,
        {
          type: 'image/jpeg',
        },
      )
      submitCrop(newImage)
    }
  }, [croppedImg, submitCrop])

  const handleCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
    try {
      if (selectedFile) {
        const croppedImageBlob = await getCroppedImg(selectedFile, croppedAreaPixels, 0, {
          horizontal: false,
          vertical: false,
        })
        setCroppedImg(croppedImageBlob)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <DrawerContent h='100%' bg='text.grey-800'>
      <DrawerBody
        gap='50px'
        px='auto'
        py='24px'
        display='flex'
        flexDirection='row'
        justifyContent='center'
      >
        <Box h='60%' w='full'>
          {selectedFile && (
            <EasyCrop
              image={selectedFile.preview}
              crop={crop}
              objectFit='contain'
              restrictPosition={true}
              zoom={zoom}
              showGrid={false}
              aspect={1.8}
              rotation={0}
              cropShape='rect'
              onCropChange={setCrop}
              onCropComplete={handleCropComplete}
              onZoomChange={setZoom}
              style={{
                cropAreaStyle: {},
              }}
            />
          )}
        </Box>
      </DrawerBody>
      <DrawerFooter
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        px='48px'
        py='32px'
      >
        <Button
          onClick={onClose}
          variant='ghost'
          color='text.green'
          _focusVisible={{ outline: 'none!' }}
        >
          انصراف
        </Button>
        <Button onClick={handleClick} variant='ghost' color='text.green'>
          انتخاب تصویر
        </Button>
      </DrawerFooter>
    </DrawerContent>
  )
}
export default CropperBS
