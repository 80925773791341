import {
  Button,
  Checkbox,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  HStack,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useState } from 'react'
import { BottomSheetActions } from '../../store/slices/bottomSheetSlice'
import { RootState } from '../../store/store'

type RadioItemType = {
  itemKey: string
  value: string
}

export type RadioDataProps = {
  title: string
  inputTitle: string
  placeholder?: string
  selectedItems?: RadioItemType[]
  options: RadioItemType[]
  onClose?: () => void
  onSubmit: (data: string[]) => void
}

const RadioButtonSheet = () => {
  const { selectedItems, options, onSubmit }: RadioDataProps = useSelector(
    (state: RootState) => state.bottomSheet.data,
  )
  const dispatch = useDispatch()
  const closeBS = useCallback(() => {
    dispatch(BottomSheetActions.closeBottomSheet())
  }, [])
  const [selectedOptions, setSelectedOptions] = useState<RadioItemType[]>(selectedItems ?? [])

  return (
    <DrawerContent autoFocus={false} borderTopRadius='1.75rem'>
      <DrawerHeader
        alignItems='center'
        justifyContent='center'
        w='full'
        display='flex'
        flexDir='row'
        p='1.63rem 1rem 1.13rem'
        borderBottom='1px solid'
        borderColor='background.divider'
        pos='relative'
      >
        <Text textStyle='titleXSmall' fontWeight='bold' textColor='text.grey-800'>
          انتخاب خدمات انجام شده
        </Text>
        <CloseIcon onClick={closeBS} boxSize='5' pos='absolute' left='1rem' p='1' />
      </DrawerHeader>
      <DrawerBody overflowY='scroll' w='full' p='1.5rem'>
        <VStack
          justifyContent='space-between'
          w='full'
          overflowY='hidden'
          flexDir='column'
          borderRadius='0.5rem'
          border='1px solid'
          borderColor='background.divider'
        >
          <Stack
            overflowY='auto'
            filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.05))'
            borderRadius='8px'
            bg='white'
            w='full'
          >
            {options.map(({ itemKey, value }, index) => (
              <HStack
                borderBottom='1px solid'
                _last={{ borderBottom: 'none' }}
                borderColor='background.divider'
                key={index}
                py='0.5rem'
                px='1rem'
              >
                <Checkbox
                  onChange={(e) => {
                    const isChecked = e.target.checked
                    if (isChecked) {
                      setSelectedOptions([...selectedOptions, { itemKey, value }])
                    } else {
                      const newSelected = selectedOptions?.filter(
                        (item) => item.itemKey !== itemKey,
                      )
                      setSelectedOptions(newSelected ?? [])
                    }
                  }}
                  defaultChecked={!!selectedItems?.find((item) => item.itemKey === itemKey)}
                  colorScheme='purple'
                />
                <Text textStyle='bodySmallRegular' py='14px!' mx='16px!'>
                  {value}
                </Text>
              </HStack>
            ))}
          </Stack>
        </VStack>
      </DrawerBody>
      <DrawerFooter w='full'>
        <Button
          onClick={() => {
            onSubmit(selectedOptions.map((item) => item.itemKey))
            closeBS()
          }}
          disabled={!selectedOptions || selectedOptions.length === 0}
          w='full'
        >
          ثبت
        </Button>
      </DrawerFooter>
    </DrawerContent>
  )
}
export default RadioButtonSheet
