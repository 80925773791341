import Cookies from 'universal-cookie'
import { ACCESS_TOKEN, DEVICE_ID, FCM_TOKEN, VIEWER_ID } from './consts'
import jwtDecode from 'jwt-decode'
import { JWTDataDTO } from '../api/user/User.dto'
import { v4 as uuidv4 } from 'uuid'

const cookies = new Cookies()
const cookieDomain = import.meta.env.MODE === 'development' ? 'localhost' : 'karnameh.com'

export const getFcmTokenFromCookie = () => {
  return cookies.get(FCM_TOKEN)
}

export const setFcmTokenCookie = (fcmToken: string) => {
  const expires = new Date()
  expires.setMonth(expires.getMonth() + 1)
  cookies.set(FCM_TOKEN, fcmToken, { expires, path: '/' })
}

export const getAccessTokenFromCookie = () => {
  return cookies.get(ACCESS_TOKEN)
}

export const setAccessTokenCookie = (accessToken: string) => {
  const jwtToken = jwtDecode<JWTDataDTO>(accessToken)
  const expires = new Date(jwtToken.exp * 1000)
  cookies.set(ACCESS_TOKEN, accessToken, { expires, path: '/', domain: cookieDomain })
}

export const getDeviceIdFromCookie = () => {
  return cookies.get(DEVICE_ID)
}

export const setDeviceIdCookie = (deviceId: string) => {
  const expires = new Date()
  expires.setMonth(expires.getMonth() + 1)
  cookies.set(DEVICE_ID, deviceId, { expires, path: '/' })
}

export const setViewerIdCookie = () => {
  const expires = new Date()
  expires.setMonth(expires.getMonth() + 1)
  if (!cookies.get(VIEWER_ID)) {
    cookies.set(VIEWER_ID, uuidv4(), {
      expires,
      path: '/',
      domain: cookieDomain,
    })
  }
}

export const getViewerId = () => {
  return cookies.get(VIEWER_ID) ?? undefined
}
export const getCookieByKey = (key: string) => {
  return cookies.get(key)
}

export const setCookieByKey = (key: string, value: string) => {
  const expires = new Date()
  expires.setDate(expires.getDate() + 1)
  cookies.set(key, value, { expires, path: '/' })
}

export const removeCookie = (key: string) => {
  cookies.remove(key, { domain: cookieDomain, path: '/' })
}

export const clearCredentialCookies = () => {
  cookies.remove(FCM_TOKEN)
  cookies.remove(ACCESS_TOKEN, { domain: cookieDomain, path: '/' })
  cookies.remove(DEVICE_ID)
}
