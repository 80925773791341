import { TriangleDownIcon } from '@chakra-ui/icons'
import { Box, Center, DrawerContent, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { PlateType } from '../../../api/car-profile/CarProfile.dto'
import { strings } from '../../../constants/strings'
import { useBottomSheet } from '../../../store/slices/bottomSheetSlice'
import { RootState } from '../../../store/store'
import DashedInput from '../../common/DashedInput'
import SubmitButton from '../../common/SubmitButton'
import { BottomSheetHeader } from '../../common/bottomsheet/BottomSheetHeader'
import { plateChars } from '../CarDetailStaticContent'

export type PlateDataType = {
  onSubmit: (data: PlateType) => void
  plate: PlateType | undefined
}
export const PlateBottomSheet = () => {
  const { onSubmit, plate } = useSelector(
    (state: RootState): PlateDataType => state.bottomSheet.data,
  )
  const [firstTwoDigits, setFirstTwoDigits] = useState<string | undefined>(
    plate ? plate.first_two_digits : undefined,
  )
  const [regionNumber, setRegionNumber] = useState<string | undefined>(
    plate ? plate.region_number : undefined,
  )
  const [letter, setLetter] = useState<string>(plate ? plate.letter : 'ب')
  const [threeDigits, setThreeDigits] = useState<string | undefined>(
    plate ? plate.second_three_digits : undefined,
  )

  const [isPlateKBOpen, setPlateKBOpen] = useState(false)
  const [plateKBPos, setPlateKBPos] = useState(100)
  const { closeBS } = useBottomSheet()
  const timeoutRef = useRef<NodeJS.Timeout>()
  const kbOverlayRef = useRef<HTMLDivElement>(null)
  const threeDigitsInputRef = useRef<HTMLInputElement>(null)
  const regionInputRef = useRef<HTMLInputElement>(null)

  const clearTimer = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
  }, [])

  const closeCharKB = useCallback(() => {
    clearTimer()
    setPlateKBPos(100)
    timeoutRef.current = setTimeout(() => {
      setPlateKBOpen(false)
    }, 300)
  }, [])

  const toggleKBHandler = useCallback((event?: React.MouseEvent) => {
    setPlateKBOpen((prev) => !prev)
    setTimeout(() => {
      setPlateKBPos((prev) => (prev === 0 ? 100 : 0))
    }, 1)
    event?.stopPropagation()
  }, [])

  const moveFocusToSecondInput = useCallback(() => {
    threeDigitsInputRef.current?.focus()
  }, [])
  const moveFocusToThirdInput = useCallback(() => {
    regionInputRef.current?.focus()
  }, [])

  const charClickHandler = useCallback((item: string) => {
    setLetter(item)
    closeCharKB()
    moveFocusToSecondInput()
  }, [])

  const checkTapOut = useCallback((event: React.MouseEvent) => {
    if (kbOverlayRef.current?.contains(event.target as Node)) {
      closeCharKB()
    }
  }, [])

  const firstChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstTwoDigits(event.target.value)
  }, [])

  const middleChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setThreeDigits(event.target.value)
  }, [])

  const lastChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRegionNumber(event.target.value)
  }, [])

  useEffect(() => {
    return () => {
      clearTimer()
    }
  }, [])

  const lastCompleteHandler = useCallback(() => {
    regionInputRef.current?.blur()
  }, [])

  const submitHandler = useCallback(() => {
    if (!firstTwoDigits || !threeDigits || !regionNumber) return
    onSubmit({
      first_two_digits: firstTwoDigits,
      letter: letter,
      second_three_digits: threeDigits,
      region_number: regionNumber,
    })
  }, [letter, firstTwoDigits, threeDigits, regionNumber])

  return (
    <DrawerContent h='100%'>
      <BottomSheetHeader title={strings.plateBottomSheet.title} onClose={closeBS} />
      <Flex padding='16px 16px 24px' flexDir='column'>
        <Text textStyle='bodySmallRegular' mb='24px' color='text.grey-800'>
          {strings.plateBottomSheet.description}
        </Text>
        <Flex
          borderRadius='8px'
          border='1px solid'
          borderColor='text.secondary'
          align='center'
          pe='13px'
          justify='space-between'
          overflow='hidden'
          _focusWithin={{ borderColor: 'accent.enable' }}
          flexDir='row-reverse'
          h='56px'
        >
          <Flex flexDir='row-reverse' justify='space-between' w='full'>
            <DashedInput
              h='28px'
              w='59px'
              charLength={2}
              textStyle='bodyLargeRegular'
              fontSize='18px'
              me='16px'
              onChange={firstChangeHandler}
              onComplete={toggleKBHandler}
              initialValue={firstTwoDigits}
            />
            <Flex flexDir='row' align='center' onClick={toggleKBHandler}>
              <Text textStyle='bodyLargeRegular' me='15px'>
                {letter}
              </Text>
              <TriangleDownIcon boxSize='10px' color='text.primary' />
            </Flex>
            <DashedInput
              ref={threeDigitsInputRef}
              initialValue={threeDigits}
              onComplete={moveFocusToThirdInput}
              h='28px'
              w='57px'
              charLength={3}
              textStyle='bodyLargeRegular'
              fontSize='18px'
              ms='16px'
              me='38px'
              onChange={middleChangeHandler}
            />
          </Flex>
          <Center bgColor='background.default' w='75px' h='full' flexDir='column'>
            <Text fontSize='12px' fontWeight='bold' lineHeight='20px' color='text.primary'>
              {strings.plateBottomSheet.iran}
            </Text>
            <DashedInput
              h='28px'
              w='40px'
              charLength={2}
              textStyle='bodySmallRegular'
              fontSize='14px'
              ref={regionInputRef}
              initialValue={regionNumber}
              onChange={lastChangeHandler}
              onComplete={lastCompleteHandler}
            />
          </Center>
        </Flex>
      </Flex>
      {isPlateKBOpen && (
        <Flex
          flexDir='column'
          h='full'
          w='full'
          zIndex={1}
          transform={`translateY(${plateKBPos}%)`}
          transition='transform 0.3s'
          position='fixed'
          bottom='0'
        >
          <Flex ref={kbOverlayRef} w='full' h='100%' onClick={checkTapOut} />
          <Grid
            templateColumns='repeat(4, 1fr)'
            p='24px 16px'
            bgColor='white'
            width='full'
            rowGap='4px'
            textAlign='center'
            boxShadow='0px 2px 2px rgba(97, 100, 117, 0.06), 0px 3px 1px rgba(97, 100, 117, 0.04), 0px 1px 5px rgba(97, 100, 117, 0.12)'
          >
            {plateChars.map((item, index) => (
              <GridItem key={index} onClick={() => charClickHandler(item)}>
                <Text textStyle='bodyMediumRegular'>{item}</Text>
              </GridItem>
            ))}
          </Grid>
        </Flex>
      )}
      <Box pos='fixed' bottom='24px' left='0' paddingX='16px' w='full'>
        <SubmitButton
          title={strings.plateBottomSheet.submit}
          onClick={submitHandler}
          isDisabled={false}
        />
      </Box>
    </DrawerContent>
  )
}
