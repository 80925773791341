import { HStack, Link, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react'
import React from 'react'

import { MenuListItemInterface } from './data'

interface MenuListInterface {
  list: Array<MenuListItemInterface>
}
const renderMenuWithSubMenu = (item: MenuListItemInterface) => {
  const Icon = item.icon
  return (
    <Menu autoSelect={false}>
      <MenuButton
        id={`header-button-${item.id}`}
        display='flex'
        flexDirection='row'
        justifyContent='center'
        gap='.5rem'
        alignItems='center'
        marginRight='24px'
        lineHeight='2'
        color='rgb(0,0,0)'
        cursor='pointer'
        border='none'
        padding='6.4px'
        transition='color .36s ease'
        textDecoration='none'
        _active={{ backgroundColor: '#F3F2F8', borderRadius: '0.5rem' }}
        _hover={{ backgroundColor: '#F3F2F8', borderRadius: '0.5rem' }}
      >
        <HStack spacing='.5rem' alignItems='center'>
          {Icon && <Icon />}
          <Text
            fontSize='.875rem'
            fontWeight='400'
            lineHeight='1.875'
            letterSpacing='.0115'
            color='icon.default'
            _hover={{ color: '#694FB4' }}
          >
            {item.title}
          </Text>
        </HStack>
      </MenuButton>
      <MenuList
        width='30.3125rem'
        display='grid'
        backgroundColor='white'
        borderRadius='0.5rem'
        boxShadow='0px 0px 12px 0px rgba(0, 0, 0, 0.15)'
        gridTemplateColumns='repeat(2, 1fr)'
        justifyContent='center'
        alignItems='center'
        p='2rem'
        mt='-0.4rem'
      >
        {item.subMenu?.map((subItem, index) => {
          const Icon = subItem.icon
          return (
            <MenuItem gap='0.5rem' key={`${subItem.id}-${index}`} mb='0.5rem'>
              <Link
                href={subItem.link}
                display='flex'
                flexDirection='row'
                justifyContent='center'
                alignItems='center'
                gap='.5rem'
                textDecoration='none'
                _hover={{}}
              >
                <Stack
                  sx={{
                    width: '3rem',
                    height: '3rem',
                    backgroundColor: '#EBF8F1',
                    borderRadius: '0.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon type='component' />
                </Stack>
                <Text
                  fontSize='0.875rem'
                  fontStyle='normal'
                  fontWeight='400'
                  lineHeight='1.875rem'
                  color='text.secondary'
                  whiteSpace='nowrap'
                  _hover={{ color: '#694FB4' }}
                >
                  {subItem.title}
                </Text>
              </Link>
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

const AppbarMenu = ({ list }: MenuListInterface) => {
  return (
    <>
      {list.map((item) => {
        if (Object.keys(item).includes('subMenu')) {
          return renderMenuWithSubMenu(item)
        } else {
          const Icon = item.icon
          return (
            <Link
              key={item.link}
              href={item.link}
              padding='.4rem'
              display='flex'
              flexDirection='row'
              justifyContent='center'
              gap='.5rem'
              alignItems='center'
              marginRight='24px'
              fontSize='.75rem'
              fontWeight='500'
              lineHeight='2'
              cursor='pointer'
              transition='color .36s ease'
              textDecoration='none'
              _hover={{ backgroundColor: '#F3F2F8', borderRadius: '0.5rem', color: '#694FB4' }}
            >
              <Icon type='navigation' />
              <Text
                fontSize='.875rem'
                fontWeight='400'
                lineHeight='1.875rem'
                letterSpacing='.0115'
                color='icon.default'
                _hover={{ color: '#694FB4' }}
              >
                {item.title}
              </Text>
            </Link>
          )
        }
      })}
    </>
  )
}

export default AppbarMenu
