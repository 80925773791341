import { DrawerContent, Text } from '@chakra-ui/react'
import { BottomSheetHeader } from '../../common/bottomsheet/BottomSheetHeader'
import { strings } from '../../../constants/strings'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { BottomSheetFooter } from '../../common/bottomsheet/BottomSheetFooter'

export type ConfirmDataType = {
  onClose: () => void
  onConfirm: () => void
  title?: string
  ctaTitle?: string
  description?: string
  hideCancel?: boolean
  hideNotifier?: boolean
}

export const ConfirmBottomSheet = () => {
  const { onClose, onConfirm, title, ctaTitle, description, hideCancel, hideNotifier } =
    useSelector((state: RootState): ConfirmDataType => state.bottomSheet.data)
  return (
    <DrawerContent>
      <BottomSheetHeader title={title ?? strings.confirmBS.title} onClose={onClose} />
      {!hideNotifier && (
        <Text
          p='16px'
          textStyle='bodySmallRegular'
          borderRadius='8px'
          bgColor='#FFF3F5'
          color='error.icon'
          mx='16px'
          mb='8px'
        >
          {strings.confirmBS.notifier}
        </Text>
      )}
      <Text textStyle='bodySmallRegular' mx='16px' mb='8px' color='text.grey-800'>
        {description ?? strings.confirmBS.description}
      </Text>
      <BottomSheetFooter
        acceptTitle={ctaTitle ?? strings.confirmBS.confirm}
        cancelTitle={strings.confirmBS.cancel}
        hideCancel={hideCancel}
        acceptHandler={onConfirm}
        cancelHandler={onClose}
      />
    </DrawerContent>
  )
}
