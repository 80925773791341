import { Button, Flex } from '@chakra-ui/react'

export const BottomSheetFooter = ({
  acceptHandler,
  cancelHandler,
  cancelTitle,
  acceptTitle,
  hideCancel,
}: {
  acceptHandler: () => void
  cancelHandler: () => void
  acceptTitle: string
  cancelTitle: string
  hideCancel?: boolean
}) => {
  return (
    <Flex flexDir='row' padding='24px 16px' gap='16px'>
      {!hideCancel && (
        <Button
          variant='outline'
          color='illustration.brandgreen'
          onClick={cancelHandler}
          w='full'
          border='none'
        >
          {cancelTitle}
        </Button>
      )}
      <Button color='white' bg='illustration.brandgreen' onClick={acceptHandler} w='full'>
        {acceptTitle}
      </Button>
    </Flex>
  )
}
