import { HStack } from '@chakra-ui/react'
import { routePaths } from '../../../routes/urls'
import CustomizedNavLink from './CustomizedNavLink'
import HomeIcon from '../../icons/HomeIcon'
import MyCarIcon from '../../icons/MyCarIcon'
import CarPriceIcon from '../../icons/CarPriceIcon'
import CarAdsIcon from '../../icons/CarAdsIcon'
import { generateKarnameUrl } from '../../../utils/redirectionManager'

const BottomNavigationBar = () => {
  return (
    <HStack
      position='sticky'
      h='4.25ren'
      justifyContent='space-around'
      zIndex='1'
      w='full'
      boxShadow='0px 4px 5px rgba(97, 100, 117, 0.06), 0px 1px 10px rgba(97, 100, 117, 0.04), 0px 2px 4px rgba(97, 100, 117, 0.12)'
      bg='white'
      p='0.5rem'
      bottom='0'
    >
      <CustomizedNavLink
        path='/'
        activeIcon={<HomeIcon type='navigation' color='#694FB4' />}
        deActiveIcon={<HomeIcon type='navigation' />}
        title='خدمات'
      />
      <CustomizedNavLink
        path={`${routePaths.HOME.url}`}
        activeIcon={<MyCarIcon type='navigation' color='#694FB4' />}
        deActiveIcon={<MyCarIcon type='navigation' />}
        title='خودروی من'
      />

      <CustomizedNavLink
        path={generateKarnameUrl('buy-used-cars')}
        activeIcon={<CarAdsIcon type='navigation' color='#694FB4' />}
        deActiveIcon={<CarAdsIcon type='navigation' />}
        title='آکهی‌ها'
      />
      <CustomizedNavLink
        path={generateKarnameUrl('car-price')}
        activeIcon={<CarPriceIcon type='navigation' color='#694FB4' />}
        deActiveIcon={<CarPriceIcon type='navigation' />}
        title='قیمت روز'
      />
    </HStack>
  )
}
export default BottomNavigationBar
