import { Box } from '@chakra-ui/react'

function BarValues() {
  return (
    <Box display={'flex'} bg={'white'} w={'full'} h={8} dir='ltr' mb={5}>
      <Box
        px={7}
        py={0.5}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        color={'white'}
        h={'full'}
        bg={'#467D3D'}
        borderRightRadius={4}
        fontSize={'12px'}
        w={'25%'}
      >
        ارزون
      </Box>
      <Box
        px={7}
        py={0.5}
        w={'50%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        color={'white'}
        h={'full'}
        bg={'#88B153'}
        fontSize={'12px'}
      >
        منصفانه
      </Box>
      <Box
        px={7}
        py={0.5}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        color={'white'}
        h={'full'}
        bg={'#F3C14F'}
        borderLeftRadius={4}
        fontSize={'12px'}
        w={'25%'}
      >
        گرون
      </Box>
    </Box>
  )
}

export default BarValues
