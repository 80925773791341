import { HStack, Text } from '@chakra-ui/react'

export type ServiceDetailProps = {
  title: string
  price: number
}
export const PriceRowItem = ({ title, price }: ServiceDetailProps) => {
  return (
    <HStack w='full' justifyContent='space-between' mb='0.75rem !important' align='start'>
      <Text textStyle='captionMedium' color='text.secondary' maxWidth='60%'>
        {title}
      </Text>
      <Text textStyle='captionMedium' color={price < 0 ? 'icon.notification' : 'text.primary'}>
        {price.toLocaleString()} تومان
      </Text>
    </HStack>
  )
}
