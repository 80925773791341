import {
  Box,
  DrawerBody,
  DrawerContent,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@src/store/store'

import { BottomSheetHeader } from './BottomSheetHeader'

type OptionType = {
  itemKey: string
  value: string
}

export type SelectDataProps = {
  title: string
  inputTitle: string
  placeholder?: string
  defaultValue?: string
  options: string[] | OptionType[]
  onClose?: () => void
  onSubmit: (data: string) => void
  inputDisabled?: boolean
}

export const SelectBottomSheet = () => {
  const {
    title,
    inputTitle,
    placeholder,
    defaultValue,
    options,
    onSubmit,
    onClose,
  }: SelectDataProps = useSelector((state: RootState) => state.bottomSheet.data)

  const optionList = useMemo(() => {
    if (options.length < 0) return []
    if (typeof options[0] === 'string')
      return options.map((item) => ({ itemKey: item, value: item })) as OptionType[]
    return options as OptionType[]
  }, [options])

  return (
    <DrawerContent borderTopRadius='8px' pb='0!' maxH='500px'>
      <BottomSheetHeader title={title} onClose={onClose} />
      <DrawerBody overflowY='hidden' w='full' px='0!'>
        <VStack
          justifyContent='space-between'
          w='full'
          overflowY='hidden'
          flexDir='column'
          pos='relative'
        >
          <FormControl variant='floating-icon' pos='sticky' top='0' zIndex='1' bgColor='white'>
            <InputGroup px='16px'>
              <Input
                disabled={true}
                defaultValue={defaultValue}
                borderRadius='8px'
                w='full'
                height='68px'
                py='10px!'
                px='16px'
                outline='0px'
                border='1px'
                _disabled={{}}
                placeholder={placeholder}
                _focusVisible={{
                  borderColor: 'accent.enable',
                }}
                borderColor='accent.enable'
                _placeholder={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '32px',
                  color: 'text.secondary',
                }}
              />
            </InputGroup>
            {/* It is important that the Label comes after the Control due to css selectors */}
            <FormLabel
              marginInlineStart='20px!'
              _focus={{
                textColor: 'accent.enable',
              }}
              textStyle='captionLargeRegular'
              textColor='accent.enable'
              backgroundColor='white'
            >
              {inputTitle}
            </FormLabel>
          </FormControl>
          <Box w='full' px='16px'>
            <Stack
              height='400px'
              overflowY='auto'
              filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.05))'
              borderRadius='8px'
              bg='white'
              w='full'
            >
              {optionList.map(({ itemKey, value }, index) => (
                <Text
                  onClick={() => {
                    onSubmit(value)
                  }}
                  textStyle='bodySmallRegular'
                  borderBottom='1px solid'
                  borderColor='background.divider'
                  py='14px!'
                  mx='16px!'
                  key={index}
                >
                  {itemKey}
                </Text>
              ))}
            </Stack>
          </Box>
        </VStack>
      </DrawerBody>
    </DrawerContent>
  )
}
