import moment from 'moment-jalaali'
import { DateObj } from './dateTypes'
import { p2eDigitConverter } from '../../../utils/numberUtils'

export const months = [
  'فروردین',
  'اردیبهشت',
  'خرداد',
  'تیر',
  'مرداد',
  'شهریور',
  'مهر',
  'آبان',
  'آذر',
  'دی',
  'بهمن',
  'اسفند',
]

export const weekDays = ['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج']

export const jalaliIntl = new Intl.DateTimeFormat('fa-IR')

export const isLeapYear = (year: number): boolean => moment.jIsLeapYear(year)

export const getMonthDays = (year: number, month: number): number =>
  month < 7 ? 31 : month === 12 && !isLeapYear(year) ? 29 : 30

export const g2jDateFormatter = (date: Date) => {
  const jDate = p2eDigitConverter(jalaliIntl.format(date)).split('/')
  return { year: +jDate[0], month: +jDate[1], day: +jDate[2] }
}

export const g2jDateFormatterInString = (date: Date) => {
  const jDate = p2eDigitConverter(jalaliIntl.format(date)).split('/')
  const dateObj = { year: +jDate[0], month: +jDate[1], day: +jDate[2] }
  return dateObjToString(dateObj)
}

export const todayInJalali = () => g2jDateFormatter(new Date())

export const j2gDateFormatter = (date: DateObj): DateObj => {
  const d = moment(`${date.year}/${date.month}/${date.day}`, 'jYYYY/jM/jD')
    .format('YYYY/M/D')
    .split('/')
  return { year: +d[0], month: +d[1], day: +d[2] }
}

export const getStartDayOfMonth = (year: number, month: number): number => {
  const gDate = j2gDateFormatter({ year, month, day: 1 })
  return (new Date(`${gDate.year}/${gDate.month}/${gDate.day}`).getDay() + 1) % 7
}

export const dateObjToString = (date: DateObj | undefined | null, divider = '/') =>
  date ? `${date.year}${divider}${date.month}${divider}${date.day}` : ''

export const getRemindMonth = (date: Date) => {
  const diff = +date - Date.now()
  if (diff < 0) return
  return `${parseInt(`${diff / (30 * 24 * 3600000)}`)} ماه`
}

export const getExpirationStatus = (date: Date): ExpirationStatusType => {
  const today = new Date()
  if (today < date) {
    return 'GOOD'
  }
  if (today > date) return 'EXPIRED'
  if (today === date) return 'EXPIRING'
  return 'INCOMPLETE'
}

export type ExpirationStatusType = 'EXPIRED' | 'EXPIRING' | 'INCOMPLETE' | 'NA' | 'GOOD'
