import { AbsoluteCenter, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import {
  DateObj,
  getMonthDays,
  getStartDayOfMonth,
  months,
  todayInJalali,
  weekDays,
} from '../date-picker'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DropdownSelect } from '../dropdown-select/DropdownSelect'

const getArrayOfLen = (length: number) => Array.from({ length }, (_, i) => i + 1)

export const DatePicker = ({ onChange }: { onChange: (value: DateObj) => void }) => {
  const today = useMemo(() => todayInJalali(), [])
  const [selectedDate, setSelectedDate] = useState<DateObj>({
    day: today.day,
    month: today.month,
    year: today.year,
  })
  const [emptyDays, setEmptyDays] = useState<number[]>(
    getArrayOfLen(getStartDayOfMonth(selectedDate.year, selectedDate.month)),
  )

  const startYear = 1399,
    yearsLength = 20
  const years = Array.from({ length: yearsLength }, (_, i) => startYear + i)

  const monthChangeHandler = useCallback(
    (month: number | string) => {
      setSelectedDate((prev) => ({ ...prev, month: +month }))
      setEmptyDays(getArrayOfLen(getStartDayOfMonth(selectedDate.year, +month)))
    },
    [selectedDate.year],
  )
  const yearsChangeHandler = useCallback(
    (year: number | string) => {
      setSelectedDate((prev) => ({ ...prev, year: +year }))
      setEmptyDays(getArrayOfLen(getStartDayOfMonth(+year, selectedDate.month)))
    },
    [selectedDate.month],
  )
  const dateClickHandler = useCallback((day: number) => {
    setSelectedDate((prev) => ({ ...prev, day }))
  }, [])

  useEffect(() => {
    onChange(selectedDate)
  }, [selectedDate])

  return (
    <Flex direction='column'>
      <Flex direction='row' gap='16px' m='16px 0'>
        <DropdownSelect
          defaultValue={{ value: today.month, item: months[today.month - 1] }}
          options={months.map((item, index) => ({ item, value: index + 1 }))}
          onChange={monthChangeHandler}
        />
        <DropdownSelect
          defaultValue={{ value: today.year, item: today.year }}
          options={years.map((item) => ({ item, value: item }))}
          onChange={yearsChangeHandler}
        />
      </Flex>
      <Grid templateColumns='repeat(7, 1fr)' rowGap='12px' pb='16px'>
        {weekDays.map((item, index) => (
          <GridItem key={index} mb={1} p='5px 0'>
            <Text textStyle='bodySmallSemibold' color='text.deactive' textAlign='center'>
              {item}
            </Text>
          </GridItem>
        ))}
        {emptyDays.map((_, index) => (
          <GridItem key={index}></GridItem>
        ))}
        {getArrayOfLen(getMonthDays(selectedDate.year, selectedDate.month)).map((item) => (
          <GridItem
            key={item}
            pos='relative'
            onClick={() => {
              dateClickHandler(item)
            }}
          >
            {selectedDate.day === item && (
              <AbsoluteCenter
                bgColor='illustration.brandgreen'
                borderRadius='50%'
                w='32px'
                h='32px'
                zIndex='-1'
              />
            )}
            <Text
              textStyle='bodyMediumRegular'
              color={selectedDate.day === item ? 'white' : 'text.primary'}
              textAlign='center'
            >
              {item}
            </Text>
          </GridItem>
        ))}
      </Grid>
    </Flex>
  )
}
