import { HStack, Text, VStack, Img, Link } from '@chakra-ui/react'
import RestrictedArea from '../../assets/svg/RestrictedArea'
import bazarBtn from '../../assets/webp/bazar-download.webp'
import { DownloadIcon } from '@chakra-ui/icons'

const OnlyMobile = () => {
  const directDownloadLink = import.meta.env.VITE_APP_DOWNLOAD_LINK
  const cafeLink = import.meta.env.VITE_APP_DOWNLOAD_LINK_CAFE
  return (
    <VStack pt='6rem' mx='auto' width='full' height='calc(100vh-4rem)'>
      <RestrictedArea />
      <Text textStyle='bodySmall' mt='3.5ren'>
        دسترسی به این قسمت فقط توسط اپلیکیشن کارنامه یا مرورگر موبایل امکان‌پذیر است.
      </Text>
      <Text textStyle='cardCaption' textColor='text.secondary' mt='3.5rem'>
        دریافت اپلیکیشن کارنامه
      </Text>
      <HStack gap='1.5rem' mt='1.5rem' justifyContent='center' alignItems='center' w='full'>
        <Link
          display='flex'
          w='8.4rem'
          h='2.5rem'
          bg='white'
          flexDir='row'
          justifyContent='center'
          alignItems='center'
          borderRadius='0.25rem'
          border='1px solid'
          borderColor='primary.enable'
          _hover={{}}
          href={directDownloadLink}
          target='_blank'
          gap='0.25rem'
          p='0'
          width='8.4rem'
        >
          <DownloadIcon color='primary.enable' />
          <Text textColor='primary.enable'>دانلود مستقیم</Text>
        </Link>
        <Link href={cafeLink} target='_blank' p='0' w='8.4rem' h='2.5rem'>
          <Img w='8.4rem' src={bazarBtn} />
        </Link>
      </HStack>
    </VStack>
  )
}
export default OnlyMobile
