import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { BottomSheetContentType } from '../../components/bottomSheets/BottomSheets'

type BottomSheetStateType = {
  isOpen: boolean
  data?: any
  contentType?: BottomSheetContentType | null
  closeOnOverlayClick?: boolean
}

const BottomSheetInitial: BottomSheetStateType = {
  isOpen: false,
}

const BottomSheetSlice = createSlice({
  name: 'BottomSheets',
  initialState: BottomSheetInitial,
  reducers: {
    openBottomSheet(
      state,
      action: PayloadAction<{
        contentType: BottomSheetContentType
        data?: any
        closeOnOverlayClick?: boolean
      }>,
    ) {
      state.contentType = action.payload.contentType
      state.data = action.payload.data
      state.isOpen = true
      state.closeOnOverlayClick = action.payload.closeOnOverlayClick ?? false
    },
    closeBottomSheet(state) {
      // Do not Reset data and contentType, BottomSheet slide out animation will fail!
      state.isOpen = false
    },
  },
})

export const BottomSheetActions = BottomSheetSlice.actions
export const BottomSheetReducer = BottomSheetSlice.reducer

export const useBottomSheet = () => {
  const dispatch = useDispatch()
  return {
    openBS: (contentType: BottomSheetContentType, data: any, closeOnOverlayClick = false) => {
      dispatch(BottomSheetActions.openBottomSheet({ contentType, data, closeOnOverlayClick }))
      history.pushState({}, '')
    },
    closeBS: () => {
      dispatch(BottomSheetActions.closeBottomSheet())
      if (Object.keys(history.state).length === 0) history.back()
    },
  }
}

export const useCloseBottomSheet = () => {
  const dispatch = useDispatch()
  return () => {
    dispatch(BottomSheetActions.closeBottomSheet())
    if (Object.keys(history.state).length === 0) history.back()
  }
}
