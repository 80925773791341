import { DrawerContent, Flex, Input } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { strings } from '../../../constants/strings'
import { RootState } from '../../../store/store'
import { DateObj, DatePickerDataType, dateObjToString, todayInJalali } from '../date-picker'
import { DatePicker } from '../date-picker/DatePicker'
import { BottomSheetFooter } from './BottomSheetFooter'
import { BottomSheetHeader } from './BottomSheetHeader'

export const DatePickerBottomSheet = () => {
  const [date, setDate] = useState<DateObj>()
  const { onClose, onConfirm, inputTitle, title } = useSelector(
    (state: RootState): DatePickerDataType => state.bottomSheet.data,
  )

  const dateChangeHandler = useCallback((date: DateObj) => {
    setDate(date)
  }, [])

  const confirmHandler = useCallback(() => {
    onConfirm(date ?? todayInJalali())
  }, [date])

  return (
    <DrawerContent borderTopRadius='8px'>
      <BottomSheetHeader title={title} onClose={onClose} />
      <Flex direction='column' px='16px'>
        <Input
          disabled
          type='text'
          py='10px!'
          px='16px'
          mb='24px'
          title={inputTitle}
          value={dateObjToString(date)}
          outline='0px'
          border='1px'
          _focusVisible={{
            border: 'none',
          }}
          borderColor='text.deactive'
          _focus={{
            outline: 'none',
            border: '1px solid',
            borderColor: 'accent.main',
          }}
          _placeholder={{
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '32px',
            color: 'text.primary',
          }}
        />
        <DatePicker onChange={dateChangeHandler} />
      </Flex>
      <BottomSheetFooter
        acceptTitle={strings.confirm}
        cancelTitle={strings.cancel}
        acceptHandler={confirmHandler}
        cancelHandler={onClose}
      />
    </DrawerContent>
  )
}
