import { matchPath, useLocation, useRoutes, useSearchParams } from 'react-router-dom'
import { routes } from './routes/routes'
import { Box, useMediaQuery } from '@chakra-ui/react'
import { Suspense, useEffect, useMemo } from 'react'
import LoadingIndicator from './components/common/LoadingIndicator'
import './main.css'
import { BottomSheetManager } from './components/bottomSheets/BottomSheetManager'
import Appbar from './components/common/header/Appbar'
import { homeUrls, routePaths } from './routes/urls'
import BottomNavigationBar from './components/common/bottom-navigation-bar/BottomNavigationBar'
import OnlyMobile from './components/common/OnlyMobile'
import { setAppReferrer } from './utils/sessionStorageManager'
import useAppSelector from './store/hooks/useAppSelector'
import { useLoadingIndicator } from './store/slices/appSlice'
import { capturePageView, configurePostHog } from './utils/eventLogger'
import { isTwaApp } from './utils/twaUtils'
import { useComponentWillMountInClient } from './hooks/useComponentWillMountInClient'
import { setViewerIdCookie } from './utils/cookieManager'

function App() {
  const content = useRoutes(routes)
  const currentLocation = useLocation()
  const [searchParams] = useSearchParams()
  const { showLoading } = useAppSelector((state) => state.app)
  const currentRoute = Object.values(routePaths).find(
    (item) =>
      item.url === currentLocation.pathname || matchPath(item.path, currentLocation.pathname),
  )
  const showGlobalLoading = useLoadingIndicator()
  const isHomeUrl = homeUrls.includes(currentLocation.pathname)

  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const hasAppbar = currentRoute
    ? currentRoute.hasAppBar || currentRoute.hasDynamicAppBarName
    : false

  const screenHeight = useMemo(() => {
    if (hasAppbar && isHomeUrl && isMobile) return 'calc(100vh - 56px - 64px)'
    if (hasAppbar && isMobile) return 'calc(100vh - 64px)'
    if (hasAppbar) return 'calc(100vh - 64px)'
    if (isHomeUrl && isMobile) return 'calc(100vh - 56px)'
    return '100%'
  }, [hasAppbar, isHomeUrl])

  setViewerIdCookie()

  const canShowDesktop = useMemo(() => {
    // return true
    if (
      currentLocation.pathname.startsWith('/profile') ||
      currentLocation.pathname === '/notifications'
    )
      return true
    const userAgent = navigator.userAgent.toLowerCase()
    return /mobile|android|iphone|ipad|ipod|windows phone|blackberry|opera mini|kindle|webos/i.test(
      userAgent,
    )
  }, [navigator, navigator.userAgent])

  useComponentWillMountInClient(() => {
    const appReferrerQuery = searchParams.get('app_referrer')
    if (document.referrer.includes('android-app') || appReferrerQuery === 'twa' || isTwaApp()) {
      setAppReferrer('twa')
    } else {
      setAppReferrer('app')
    }
    const appVersion = searchParams.get('app_version')
    configurePostHog(appVersion)
  })

  useEffect(() => {
    showGlobalLoading(false)
  }, [])

  useEffect(() => {
    showGlobalLoading(false)
    capturePageView()
  }, [currentLocation])

  return (
    <Box bg='surface.main-bg' h='100vh' width='100%'>
      <Suspense fallback={<LoadingIndicator isFallBack={true} />}>
        {canShowDesktop ? (
          showLoading ? (
            <LoadingIndicator isFallBack={true} />
          ) : (
            <>
              {hasAppbar && <Appbar />}
              <Box className='main-container' width='100%' height={screenHeight} overflow='auto'>
                {content}
              </Box>
              <BottomSheetManager />
              {isHomeUrl && isMobile && <BottomNavigationBar />}
            </>
          )
        ) : (
          <>
            <Appbar />
            <OnlyMobile />
          </>
        )}
      </Suspense>
    </Box>
  )
}

export default App
