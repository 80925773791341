import { DrawerHeader, Text } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'

type Props = { title: string; onClose?: (...args: unknown[]) => void }

export const BottomSheetHeader = ({ onClose, title }: Props) => {
  return (
    <DrawerHeader
      py='24px'
      px='16px'
      alignItems='center'
      justifyContent='space-between'
      w='full'
      display='flex'
      flexDir='row'
    >
      <Text textStyle='titleXSmall' fontWeight='bold' textColor='text.grey-800'>
        {title}
      </Text>
      <CloseIcon onClick={onClose} boxSize='4' />
    </DrawerHeader>
  )
}
