import { useRef } from 'react';

export const useComponentWillMountInClient = (fn: () => void) => {
  const willMount = useRef(true);

  if (typeof window === 'undefined') return;

  if (willMount.current && fn) {
    fn();
  }

  willMount.current = false;
};
