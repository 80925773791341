export const ACCESS_TOKEN = 'karnameh-access-token'
export const FCM_TOKEN = 'fcmToken'
export const DEVICE_ID = 'deviceId'
export const INSPECTION_REPORT = 'inspection-report'
export const VIEWER_ID = 'viewer_id'

export const UTM_COOKIES = 'utm-cookies'

// post message states
export const NOTIFICATIONS_STATE = 'NOTIFICATIONS'
export const BACK_STATE = 'BACK'
export const HOME_STATE = 'HOME'
export const RESET_COOKIE_STATE = 'RESET-COOKIE'
export const REFRESH_DONE_STATE = 'REFRESH-DONE'
