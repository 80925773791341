import { Box, Divider, Text } from '@chakra-ui/react'

import { CarPriceEstimationDetailDto } from '@src/api/car-profile/CarProfile.dto'

type Props = CarPriceEstimationDetailDto

function Dividers({ min_price, max_price }: Props) {
  return (
    <Box className='dividers' display={'flex'}>
      <Box
        display={'flex'}
        w={'50%'}
        justifyContent={'center'}
        alignItems={'center'}
        color={'#6E6E6E'}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} w={'full'}>
          <Text maxW={'85px'} textAlign={'center'} fontSize={'14px'} sx={{ textWrap: 'nowrap' }}>
            {max_price?.toLocaleString()}
            <br />
            تومان
          </Text>
          <Divider orientation='vertical' h={4} borderColor={'#88B153'} />
        </Box>
      </Box>
      <Box
        display={'flex'}
        w={'50%'}
        justifyContent={'center'}
        alignItems={'center'}
        color={'#6E6E6E'}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} w={'full'}>
          <Text maxW={'85px'} textAlign={'center'} fontSize={'14px'} sx={{ textWrap: 'nowrap' }}>
            {min_price?.toLocaleString()}
            <br />
            تومان
          </Text>
          <Divider orientation='vertical' h={4} borderColor={'#88B153'} />
        </Box>
      </Box>
    </Box>
  )
}

export default Dividers
