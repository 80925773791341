import { ArrowForwardIcon, Icon } from '@chakra-ui/icons'
import { Flex, HStack, Image, Text, useMediaQuery } from '@chakra-ui/react'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { MdPersonOutline } from 'react-icons/md'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import karnamehLogo from '../../../assets/svg/karnameh-logo-new.svg'
import { closeableNavbarRoutes } from '../../../routes/routes'
import { routePaths } from '../../../routes/urls'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { redirectToKarnameh } from '../../../utils/redirectionManager'
import DesktopAppbar from './DesktopAppbar'
import EditPeriodicServiceIcon from './EditPeriodicServiceIcon'

// const NotificationIcon = ({ counts }: { counts?: number }) => {
//   return (
//     <Box px='12rem' position='relative' w='48px'>
//       <Flex
//         direction='row'
//         align='center'
//         justify='center'
//         w='1rem'
//         left='0'
//         top='0'
//         transform='translate(25%, 0)'
//         height='1rem'
//         borderRadius='50%'
//         bgColor='error.icon'
//         position='absolute'
//       >
//         <Text textStyle='captionMediumRegular' color='text.white'>
//           {counts}
//         </Text>
//       </Flex>
//       <Image src={Notification} alt='notification icon' w='1.5rem' height='1.5rem' />
//     </Box>
//   )
// }

const Appbar = () => {
  const navigator = useNavigate()
  const location = useLocation()
  const [isDesktop] = useMediaQuery('(min-width: 768px)')
  const { pathname } = location
  const isCloseablePage = closeableNavbarRoutes.includes(pathname)

  const {
    appbar: { dynamicTitle },
  } = useAppSelector((state) => state.app)

  const currentPath = Object.values(routePaths).find(
    (item) => item.url === pathname || matchPath(item.path, pathname),
  )
  const title = currentPath?.hasDynamicAppBarName ? dynamicTitle : currentPath?.title

  const prevRoute = currentPath?.prevRoute || null
  const isHome =
    pathname === routePaths.HOME.url ||
    pathname === routePaths.SERVICES.url ||
    pathname === routePaths.CAR_PRICE.url

  const isPeriodicServiceDetailPage = currentPath?.url === routePaths.PERIODIC_SERVICE_DETAIL.url

  return (
    <>
      {isDesktop && <DesktopAppbar />}
      {!isDesktop && (
        <Flex
          flexDir='row'
          align='center'
          justify='space-between'
          px={{ sm: '1.5rem', md: '2rem)' }}
          py='1.31px'
          boxSize='full'
          position='sticky'
          width='100vw'
          bg='white'
          zIndex='5'
          boxShadow='0px 0px 8px rgba(0, 0, 0, 0.05)'
          top={isDesktop ? '64px' : 0}
          height='64px'
          gap='10px'
        >
          {isHome && !isDesktop ? (
            <Image alt='karnameh-logo' src={karnamehLogo} h='1.61rem' w='6.5rem' />
          ) : (
            <Flex gap='8px' justify='start' alignItems='center'>
              {!isCloseablePage && (
                <ArrowForwardIcon
                  w='1.5rem'
                  onClick={() => {
                    if (location.key === 'default') {
                      redirectToKarnameh()
                    } else {
                      if (!prevRoute) navigator(-1)
                      else navigator(prevRoute)
                      // navigator(prevRoute || -1)
                    }
                  }}
                />
              )}
              <Text textStyle='appBarTitle' w='max-content'>
                {title}
              </Text>
            </Flex>
          )}
          <HStack gap='1.25rem'>
            {pathname !== routePaths.NOTIFICATION_LIST.url && !isPeriodicServiceDetailPage && (
              <Icon
                onClick={() => navigator(routePaths.NOTIFICATION_LIST.url)}
                as={IoMdNotificationsOutline}
                boxSize='1.5rem'
                color='icon.default'
              />
            )}
            {!pathname.startsWith('/profile') &&
              pathname !== '/notifications' &&
              !isPeriodicServiceDetailPage && (
                <Icon
                  onClick={() => navigator(routePaths.PROFILE.url)}
                  as={MdPersonOutline}
                  boxSize='1.5rem'
                  color='icon.default'
                />
              )}
            {isPeriodicServiceDetailPage && <EditPeriodicServiceIcon />}
          </HStack>
        </Flex>
      )}
    </>
  )
}
export default Appbar
