import {
  Box,
  Button,
  DrawerContent,
  DrawerFooter,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { HTMLInputTypeAttribute, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IoCloseCircle } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import { useCloseBottomSheet } from '../../../store/slices/bottomSheetSlice'
import { RootState } from '../../../store/store'
import { BottomSheetHeader } from './BottomSheetHeader'

export type PromptDataProps = {
  title: string
  inputTitle: string
  placeholder?: string
  buttonTitle: string
  clearable?: boolean
  thousandsSeparated?: boolean
  defaultValue?: string
  infoText?: string
  maxLength?: number
  inputMode?:
    | 'text'
    | 'search'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined
  onClose: () => void
  onSubmit: (data: string) => void
  inputType?: HTMLInputTypeAttribute | undefined
}

export const PromptBottomSheet = () => {
  const [modified, setModified] = useState(false)
  const closeBS = useCloseBottomSheet()
  const {
    title,
    inputTitle,
    placeholder,
    buttonTitle,
    clearable,
    thousandsSeparated,
    defaultValue,
    infoText,
    maxLength,
    inputMode,
    onSubmit,
    inputType,
  } = useSelector((state: RootState): PromptDataProps => state.bottomSheet.data)
  const resolver = yup.object().shape({
    data: yup
      .string()
      .length(maxLength ?? 1, 'خطا')
      .required('خطا'),
  })

  const {
    watch,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<{ data: string }>({
    resolver: yupResolver(resolver),
    mode: 'all',
    defaultValues: {
      data: !thousandsSeparated
        ? defaultValue
        : defaultValue
        ? parseInt(defaultValue).toLocaleString()
        : '',
    },
  })

  const submitHandler = useCallback(() => {
    onSubmit(getValues('data').replaceAll(',', ''))
  }, [onSubmit])

  const closeHandler = useCallback(() => {
    closeBS()
  }, [])

  const clearInput = useCallback(() => setValue('data', ''), [])

  return (
    <DrawerContent borderRadius='8px 8px 0 0'>
      <BottomSheetHeader title={title} onClose={closeHandler} />
      <Box px='16px'>
        <FormControl variant='floating-icon'>
          <InputGroup>
            <Input
              required={false}
              autoFocus
              type={inputType ?? 'text'}
              py='10px!'
              px='16px'
              inputMode={inputMode}
              outline='0px'
              border='1px'
              placeholder={placeholder}
              _focusVisible={{
                border: 'none',
              }}
              borderColor='text.deactive'
              _focus={{
                outline: 'none',
                border: '1px solid',
                borderColor: 'accent.main',
              }}
              _placeholder={{
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '32px',
                color: 'text.secondary',
              }}
              {...{
                ...register('data'),
                onChange: (e) => {
                  if (!thousandsSeparated) {
                    register('data').onChange(e)
                    return
                  }
                  const numericValue = e.target.value.replace(/[^0-9]/g, '')
                  const newValue =
                    numericValue !== '' ? parseInt(numericValue).toLocaleString() : ''
                  setValue('data', newValue)
                  setModified(true)
                },
              }}
            />
            {clearable && (
              <InputRightElement onClick={clearInput}>
                <Icon boxSize='24px' as={IoCloseCircle} color='text.deactive' />
              </InputRightElement>
            )}
          </InputGroup>

          {/* It is important that the Label comes after the Control due to css selectors */}
          <FormLabel
            _focus={{
              textColor: 'accent.enable',
            }}
            textStyle='captionLargeRegular'
            textColor='text.deactive'
            backgroundColor='white'
          >
            {inputTitle}
          </FormLabel>
        </FormControl>
        {infoText && (
          <Text textStyle='captionLargeRegular' color='text.deactive'>
            {infoText}
          </Text>
        )}
      </Box>
      <DrawerFooter p='24px 16px'>
        <Button
          fontSize='18px'
          lineHeight='36px'
          bg='illustration.brandgreen'
          _hover={{
            bg: 'illustration.brandgreen',
          }}
          _disabled={{
            bg: 'primary.deactive',
          }}
          isDisabled={!watch('data') && (modified || !defaultValue) && !errors.data?.message}
          w='full'
          h='48px'
          onClick={submitHandler}
        >
          {buttonTitle}
        </Button>
      </DrawerFooter>
    </DrawerContent>
  )
}
