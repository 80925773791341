import { ReactNode, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { userIsLoggedIn } from '../../utils/userAuthTool'
import { usePushTokenMutation } from '../../api/notification/Notification.api'
import { DEVICE_ID, FCM_TOKEN } from '../../utils/consts'
import {
  getDeviceIdFromCookie,
  getFcmTokenFromCookie,
  setDeviceIdCookie,
  setFcmTokenCookie,
} from '../../utils/cookieManager'

type Props = {
  children?: ReactNode
}
const AuthGuard = ({ children }: Props) => {
  const [searchParams] = useSearchParams()
  const pushToken = usePushTokenMutation({
    onError: () => {
      return
    },
  })

  useEffect(() => {
    const fcmTokenParam = searchParams.get(FCM_TOKEN)
    const deviceIdParam = searchParams.get(DEVICE_ID)

    if (fcmTokenParam && deviceIdParam) {
      setDeviceIdCookie(deviceIdParam)
      setFcmTokenCookie(fcmTokenParam)
    }

    if (userIsLoggedIn()) {
      if (getFcmTokenFromCookie() && getDeviceIdFromCookie()) {
        pushToken.mutate({
          token: getFcmTokenFromCookie(),
          device_id: getDeviceIdFromCookie() ?? '',
          app_id: 'M',
        })
      }
    }
  }, [])

  // if (!userIsLoggedIn()) {
  //   return <Navigate to={routePaths.AUTH.url} replace={true} />
  // }
  return <>{children}</>
}
export default AuthGuard
