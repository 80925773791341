const APP_REFERRER_KEY = 'karnameh-app-referrer'
export const setAppReferrer = (value: 'twa' | 'app') => {
  if (typeof sessionStorage === 'undefined') return
  sessionStorage.setItem(APP_REFERRER_KEY, value)
}

export const getAppReferrer = () => {
  if (typeof sessionStorage === 'undefined' || !sessionStorage.getItem(APP_REFERRER_KEY)) return ''
  return sessionStorage.getItem(APP_REFERRER_KEY)
}

export const removeAppReferrer = () => {
  if (typeof sessionStorage === 'undefined') return
  sessionStorage.removeItem(APP_REFERRER_KEY)
}
